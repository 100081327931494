.s-footer-navigation {
    padding-top: 2.4rem;

    @include media-breakpoint-up(md) {
        padding-top: 4rem;
    }

    .footer_nav {
        width: auto;

        > ul,
        > ul > li {
            box-sizing: border-box; // 1

            &::before,
            &::after {
                box-sizing: border-box; // 1
            }
        }

        > ul {
            @include make-row();

            > li {
                @include make-col-ready();
                @include make-col(12);
                padding-top: 1.2rem;
                padding-bottom: 1.2rem;

                @include media-breakpoint-up(md) {
                    @include make-col(6);
                    padding-top: 0;
                    padding-bottom: 4rem;
                }
                @include media-breakpoint-up(xl) {
                    @include make-col(3);
                }

                li {
                    padding: 0.7rem 0;

                    @include media-breakpoint-up(md) {
                        padding: 0;
                    }

                    a {
                        color: currentColor;
                    }
                }
            }
        }

        .s-h5 {
            margin-top: 0;
            margin-bottom: 0;
            display: block;

            @include media-breakpoint-up(md) {
                margin-bottom: 1.2rem;
            }
        }
    }

    // SOCIAL ICONS
    .s-footer-nav--inspiration {

        &::after {
            content: '';
            clear: both;
        }

        li.s-footer-nav--youtube,
        li.s-footer-nav--instagram,
        li.s-footer-nav--twitter,
        li.s-footer-nav--facebook {
            float: left;
            margin-right: 4rem;
            margin-top: 1.3rem;
            margin-bottom: 1.4rem;

            @include media-breakpoint-up(md) {
                margin-right: 2.4rem;
                margin-top: 1.5rem;
                margin-bottom: 1.7rem;
            }

            &:last-of-type {
                margin-right: 0;
            }

            a {
                background-repeat: no-repeat;
                width: $icon-size-lg;
                height: $icon-size-lg;
                display: block;

                span {
                    @include sr-only();
                }

                &.s-footer-nav--facebook {
                    background-image: url('../images/social_media/myh-icon_facebook.svg');
                }
                &.s-footer-nav--twitter {
                    background-image: url('../images/social_media/myh-icon_twitter.svg');
                }
                &.s-footer-nav--instagram {
                    background-image: url('../images/social_media/myh-icon_instagram.svg');
                }
                &.s-footer-nav--youtube {
                    background-image: url('../images/social_media/myh-icon_youtube.svg');
                    width: 2.8rem;
                }
            }
        }
    }

    // TRUSTED SHOPS
    .s-footer-nav--trusted-shops > div > div > div > div {
        // sass-lint:disable no-important
        border: 0 none !important;

        ._zbxp0s {
            background: $color_white !important;
        }
        // sass-lint:enable no-important
    }
}
