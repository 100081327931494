.s-cms-content-section {
    margin-top: 4.8rem;
}

.s-cms-content {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;

    @include media-breakpoint-up(md) {
        padding-top: 3.2rem;
        padding-bottom: 3.2rem;
    }

    .s-cms-content__headline {
        margin-top: 0;
        text-align: center;
        margin-bottom: 2.4rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 3.7rem;
        }
    }

    img {
        width: 100%;
    }

    .action.highlight,
    .action.secondary,
    .action.primary:not(.tocart) {
        @include media-breakpoint-up(xxs) {
            width: auto;
            min-width: $button-primary__width;
            display: inline-flex;
        }
    }

    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 0;
        height: 0;
        overflow: hidden;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .product-group__list {
        padding-top: 0;
    }

    @supports (backdrop-filter: none) {
        .s-cms-content__action--blurred {
            backdrop-filter: blur(2px);
            border-radius: $button__border-radius;
        }
    }
}

.s-cms-content__wysiwyg {
    h2 {
        @include lib-heading(h3);
        margin-top: $h2__margin-bottom;
    }

    h3 {
        @include lib-heading(h4);
        margin-top: $h3__margin-bottom;
    }

    h4,
    h5,
    h6 {
        @include lib-heading(h5);
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    p {
        text-align: left;
        margin-top: 0;
        margin-bottom: 2.4rem;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.s-cms-content__carousel-items {

    &.s-cms-content__carousel-items-1,
    &.s-cms-content__carousel-items-2 {
        &:not(.swiper-initialized),
        .s-cms-content__carousel:not(.swiper-initialized) {
            justify-content: center;
        }
    }

    &:not(.swiper-initialized),
    .s-cms-content__carousel:not(.swiper-initialized) {
        @include make-row();
        width: 100%;

        .s-cms-content__carousel-item {
            @include make-col(12);

            @include media-breakpoint-up(md) {
                @include make-col(6);
            }
            @include media-breakpoint-up(lg) {
                @include make-col(3);
            }
        }

        &.s-cms-content__carousel--50 {
            .s-cms-content__carousel-item {
                @include make-col(6);

                @include media-breakpoint-up(lg) {
                    @include make-col(3);
                }
            }
        }

        .swiper-slide {
            height: auto;
            width: auto;
        }
    }

    .s-cms-content__carousel-item-padding {
        padding: 0 1.5rem 1.5rem;
    }
}

// BACKGROUND COLOR STYLING
.s-invert-colors,
.bg-sale,
.bg-exclusive,
.bg-sustainability,
.bg-golf,
.bg-m5 {
    color: $text__color__reversed;

    .action.primary {
        background: $color-white;
        border-color: $color-white;
        color: $text__color;

        @include hover-focus-active() {
            background: $button-primary__background;
            border: $button-primary__border;
            color: $button-primary__color;
        }
    }
    .action.secondary {
        filter: invert(1);
    }

    .s-cms-content__brand-icon {
        .s-icon {
            fill: $color-white;
        }
    }

    .s-link {
        color: $text__color__reversed;
    }

    .swiper-pagination-bullet::before {
        background: $text__color__reversed;
    }
}

.bg-spring,
.bg-summer,
.bg-autumn,
.bg-winter {

    .s-link {
        color: $text__color;
    }
}

.s-cms-content-section--bg-color {
    .s-cms-content {
        padding-left: 2rem;
        padding-right: 2rem;

        @include media-breakpoint-up(md) {
            padding-left: 3.8rem;
            padding-right: 3.8rem;
        }
    }

    &.bg-m5 + &.bg-m5,
    &.bg-golf + &.bg-golf,
    &.bg-sale + &.bg-sale,
    &.bg-spring + &.bg-spring,
    &.bg-summer + &.bg-summer,
    &.bg-autumn + &.bg-autumn,
    &.bg-winter + &.bg-winter,
    &.bg-exclusive + &.bg-exclusive,
    &.bg-sustainability + &.bg-sustainability {
        margin-top: 0;
    }
}

// BUTTON BRAND ICON
$brand-icon__width: 3.8rem;
$brand-icon__top: calc(50% - 1px);

.icon-exclusive,
.icon-meyer,
.icon-golf,
.bg-exclusive,
.bg-meyer,
.bg-golf {

    .action.secondary,
    .action.primary {

        .s-icon-container {
            padding-left: 4.6rem;

            .s-icon {
                width: $brand-icon__width;
                top: $brand-icon__top;
            }
        }
    }
}

.icon-m5,
.bg-m5 {

    .action.secondary,
    .action.primary {

        .s-icon-container {

            .s-icon {
                width: $brand-icon__width;
                top: $brand-icon__top;
            }
        }
    }
}
