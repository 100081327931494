body {
    &._productFullscreen {
        overflow:hidden;
    }

    &._productZoom {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}
.s-product-gallery-fullscreen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: $zindex-fixed;

    .slick-track,
    .slick-list,
    .s-product-gallery-fullscreen__slider,
    .s-product-gallery-fullscreen__content {
        height: 100%;
    }
}

.s-product-gallery-zoom {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: $zindex-fixed;
    cursor: grab;

    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    img {
        max-height: unset;
        height: auto;
        width: auto;
        max-width: unset;
        display: block;
        margin: auto;
    }
}

.s-product-gallery-fullscreen__overlay {
    background-color: $modal-overlay__background-color;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.s-product-gallery-fullscreen__slider {

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
            max-height: calc(100vh - 4rem);
            height: auto;
            width: auto;
            max-width: 100%;
        }

        iframe {
            height: calc(100vh - 4rem);
            left: 0;
            position: absolute;
            top: 2rem;
            width: 100%;
        }
    }
}

.s-product-gallery-fullscreen__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 10;
    cursor: pointer;
    background-position: center;
    background-size: cover;
    background-image: $icon__close;
    width: $icon-size-lg;
    height: $icon-size-lg;

    > span {
        @include sr-only();
    }
}
