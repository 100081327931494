@include media-breakpoint-up(md) {
    .opc-wrapper .fieldset > .field.required,
    .opc-wrapper .fieldset > .field._required {
        position: static;
    }
}

.opc-wrapper .fieldset > .field > .label {
    font-weight: $font-weight__base;
}

.checkout-agreement.field {
    padding-left: 3.8rem;
    display: flex;

    .label {
        margin-top: -0.4rem;
        padding-left: 1.5rem;

        .action-show {
            text-align: left;
        }


        &::after {
            display: none;
        }

    }

    &::before {
        display: none;
    }

}
