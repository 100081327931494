body.sticky-cart-button {

    #hubspot-messages-iframe-container {

        @include media-breakpoint-down(md) {
            display: none !important;
        }
        @include media-breakpoint-up(md) {
            bottom: calc(#{$sticky-cart-height-md} + 1rem) !important;
        }
        @include media-breakpoint-up(xl) {
            bottom: calc(#{$sticky-cart-height-xl} + 1rem) !important;
        }
    }
}
