.cart {
    border-bottom: none;
    margin-top: 3.6rem;
    margin-bottom: 0;
}

.cart.table-wrapper .item-options {
    @include lib-font-size();
    font-weight: $font-weight__light;
}

.cart.table-wrapper .product-item-name {
    font-weight: $font-weight__semibold;
    line-height: 1.2;
    margin-bottom: 0;
}

.product-item-details .product-item-description {
    line-height: 1.2;
    margin: 0;
}

.cart.table-wrapper .col.qty,
.order-items.table-wrapper .col.qty {
    text-align: center;
}


.cart.main.actions {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 0;
    margin-bottom: 3rem;
}

.item-actions {
    position: absolute;
    right: 0;
    top: 0;

    td {
        padding: 0;
    }


    &--mobile {
        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &--desktop {
        display: none;

        @include media-breakpoint-up(md) {
            display: table-cell;
            position: absolute;
            bottom: 4rem;
            right: 0;
            top: unset;
        }
    }
}

.item-options {
    dt,
    dd {
        font-weight: $font-weight__base;
        line-height: 1.2;
    }
}

.cart.items {
    thead {
        display: none;
        @include lib-font-size($font-size__xs);
        border-bottom: 0.1rem solid $summary-totals-color;
    }

    .field.qty {
        border-color: $color-black;

        @include media-breakpoint-up(md) {
            margin-top: -1rem;
        }
    }
}

.item-info {
    td {
        padding-bottom: 4rem;
    }
}

.cart.item {
    .cart-price.special-price,
    .cart-price .old-price + .price {
        color: $color-sale;
    }

    .product-item-details {
        max-width: 19rem;
        padding-top: 0.5rem;

        @include media-breakpoint-down(xxs) {
            max-width: 12rem;
        }
    }

    .product-item-photo {
        top: 0;
        max-width: 8rem;
    }

    .col.price,
    .col.qty,
    .col.subtotal,
    .col.item {
        font-weight: $font-weight__base;
    }

    .col.price,
    .col.qty,
    .col.subtotal {
        padding-top: 0;
        @include lib-font-size($font-size__xs);

        &::before {
            font-weight: $font-weight__base;
        }
    }

    .col.qty {
        text-align: center;
    }

    .col.price {
        padding-left: 0;
        text-align: left;
    }

    .col.subtotal {
        padding-right: 0;
        text-align: right;
    }

    .input-text.qty {
        padding-top: 0.3rem;
        border-color: $color-black;
        border-radius: 0.2rem;
        width: 4.3rem;
        height: 3.4rem;
        margin: 0 auto;
    }
}

.cart.table-wrapper .item .col.item {
    padding-top: 0;
    padding-left: 9rem;
    margin-bottom: 3rem;
}

.cart.table-wrapper .items > .item,
.cart.table-wrapper .items thead + .item {
    border-bottom: none;
    border-top: none;
}

.cart-summary .block .fieldset.amcoupons-main-container {
    margin: 0;

    input {
        outline: none;

        &::placeholder {
            font-weight: $font-weight__base;
        }
    }
}


//
// Tablet
// ----------------------------------
@include media-breakpoint-up(md) {
    .cart {
        margin-top: 0;
    }

    .cart.main.actions {
        margin-bottom: 0;
    }

    .cart.table-wrapper {

        .col.subtotal {
            padding-right: 0;
        }

        .col.item {
            padding-left: 0;
        }
    }

    .cart.table-wrapper .item .col.item {
        padding-left: 0;
    }

    .cart.table-wrapper .item .col.item {
        padding-left: 0;
    }

    .cart.items {
        thead {
            display: table-header-group;
        }

        .col.price {
            text-align: left;
            padding-left: 0;
        }
    }

    .cart.item {
        .col.item,
        .col.price,
        .col.qty,
        .col.subtotal {
            padding-top: 4rem;
        }
    }

    .cart.main.actions {
        flex-direction: row;
        justify-content: space-between;
    }

    .cart.table-wrapper .item .col.item {
        padding-top: 4rem;
    }

    .cart.table-wrapper .item-actions {

        .actions-toolbar {
            text-align: right;
        }
    }

}
