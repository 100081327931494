.s-cms-content--teaser-fullscreen {

    .s-cms-content__text {
        p {
            margin-bottom: 0;
            margin-top: 2.4rem;
            text-align: center;
        }
    }

    img {
        display: block;
    }

    .s-cms-content-image-full--default {
        .s-cms-content__action-wrapper {
            position: absolute;
            left: 0;
            right: 0;
            top: 90%;
            transform: translateY(-50%);
            display: flex;
            justify-content: center;

            .s-cms-content__action {
                display: inline-block;
            }
        }
    }
}
