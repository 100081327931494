//
//  Slick slider styles
//  _____________________________________________

.slick-slider {
    box-sizing: border-box;
    display: block;
    position: relative;
    touch-action: pan-y;
    user-select: none;
}
.slick-list {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-track {
    display: block;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 0;

    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    [dir='rtl'] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        border: 1px solid transparent;
        display: block;
        height: auto;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-prev {
    left: 2rem;
    &:before {
        content: "";
        transform: rotate(90deg);
    }
}

.slick-next {
    right: 2rem;
    &:before {
        content: "";
        transform: rotate(-90deg);
    }
}

.slick-prev,
.slick-next {
    background: $button__background;
    border: $button__border;
    color: $button__color;
    border-radius: 2.4rem;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 4.8rem;
    line-height: 0;
    outline: none;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 4.8rem;
    z-index: 101;

    &.slick-disabled {
        opacity: 0;
    }

    &:before {
        background-image: $icon__skip-down--black;
    }

    &:before,
    &:after {
        opacity: 1;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: center;
    }

    &:hover,
    &:active,
    &:focus {
        background: $button__hover__background;
        border: $button__hover__border;
        color: $button__hover__color;
        box-shadow: none;

        &:active {
            box-shadow: none;
        }

        &:before {
            background-image: $icon__skip-down--white;
        }
    }
}

.slick-dots {
    @include lib-inline-block-space-item();
    display: block;
    list-style: none;
    margin: 0;
    padding: 15px 0;
    text-align: center;
    width: 100%;

    li {
        cursor: pointer;
        display: inline-block;
        margin: 0;
        padding: 0;
        position: relative;
        vertical-align: middle;

        &:only-child {
            display: none;
        }

        button {
            background: none;
            border-radius: 0;
            box-shadow: none;
            cursor: pointer;
            display: flex;
            padding: 0;
            text-indent: -99999px;
            width: 19px;
            height: 6px;
            justify-content: center;
            align-items: center;
            margin: 0;
            font-size: 0;
            line-height: 0;
            border: 0 none;

            &:active,
            &:focus,
            &:not(.primary) {
                box-shadow: none;
            }

            &::after {
                content: '';
                width: 4px;
                height: 4px;
                opacity: 0.8;
                transition: 0.3s;
                background: $primary__color;
                border-radius: 2px;
            }
        }
    }

    li button:hover::after,
    li.slick-active button::after {
        width: 6px;
        height: 6px;
        border-radius: 3px;
    }
}
