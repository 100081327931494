.s-recommended-belts-popup__button {
    display: none;
}

.s-recommended-belts-modal__modal {
    width: 100%;
}

.s-recommended-belts-popup {
    .product-items {
        margin-left: -30px;
    }

    .product-item {
        padding-left: 30px;
    }
}

.s-recommended-belts-popup__actions {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    @include media-breakpoint-up(md) {
        flex-direction: row;
    }

    .s-link--icon-close {
        color: $color-black;

        @include media-breakpoint-down(sm) {
            margin-right: 0;
        }
    }
}


//
// Desktop
// ---------------------------
@include media-breakpoint-up(md) {
    .s-recommended-belts-modal__modal {
        max-width: var(--modal-slide__fixed__width);
        width: 100%;
    }
}
