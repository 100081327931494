.action.clear {
    @include lib-button-reset();
}

a.action.highlight,
a.action.primary {
    @include lib-link-as-button();
}

.action.highlight,
.action.secondary,
.action.primary {
    @include lib-button-primary();
    display: flex;
    justify-content: center;
    align-items: center;

    .s-icon-container {
        position: relative;
        display: inline-block;
        padding-left: $icon-size-button + 0.8rem;

        .s-icon {
            width: $icon-size-button;
            height: $icon-size-button;
            position: absolute;
            fill: currentColor;
            left: 0;
            top: calc(50%);
            transform: translate(0, -50%);
        }
    }

    &:hover,
    &:focus,
    &:active {
        .s-icon {
            fill: currentColor;
        }
    }

    a {
        color: $button-link__color;

        &:hover {
            text-decoration: none;
        }
    }
}

.action.secondary {
    @include lib-button-revert-secondary-color;
    padding: $button__padding;
    line-height: $button__line-height;

    @include media-breakpoint-up(xl) {
        padding: $button__padding__xl;
    }

    &:hover,
    &:focus,
    &:active {
        .s-icon {
            fill: currentColor;
        }
    }
}

.action.highlight {
    @include lib-button-revert-secondary-color(
        $_button-color             : $button-highlight__color,
        $_button-background        : $button-highlight__background,
        $_button-border            : $button-highlight__border,

        $_button-color-hover       : $button-highlight__hover__color,
        $_button-background-hover  : $button-highlight__hover__background,
        $_button-border-hover      : $button-highlight__hover__border,

        $_button-color-active      : $button-highlight__active__color,
        $_button-background-active : $button-highlight__active__background,
        $_button-border-active     : $button-highlight__active__border
    );
    padding: $button__padding;
    line-height: $button__line-height;

    @include media-breakpoint-up(xl) {
        padding: $button__padding__xl;
    }

    &:hover,
    &:focus,
    &:active {
        .s-icon {
            fill: currentColor;
        }
    }
}

.action.tocart {
    span {
        display: flex;
        @include s-icon-background-before('cart', 'lg', false);

        &::before {
            margin-right: 0.5rem;
            height: 1.7rem;
        }
    }
}

a.action.secondary {
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }
}

.s-button-link {
    border-radius: 0;
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: normal;
    padding: 0;
    color: $link__color;

    &:active,
    &:hover,
    &:focus {
        border: none;
        outline: none;
        background-color: transparent;
    }

    &:hover {
        text-decoration: underline;
        color: $link__color;
    }

    .s-icon {
        width: 2.5rem;
        height: 2.5rem;
        fill: $link__color;
        margin-bottom: -0.6rem;
    }
}
