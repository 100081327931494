.s-top-categories {
    padding-bottom: 2.4rem;

    @include media-breakpoint-up(md) {
        padding-bottom: 4rem;
    }
}

.s-top-categories__listWrapper {

    ul {

        a {
            color: currentColor;
        }
    }
}

.s-top-categories__headline {
    margin: 0;
    padding-bottom: 0.8rem;

    @include media-breakpoint-up(md) {
        padding-bottom: 4rem;

        .s-accordion__button {
            text-align: center;
        }
    }

    span {
        @include lib-heading(h5);

        @include media-breakpoint-up(md) {
            @include lib-heading(h2);
        }
    }
}

.s-top-categories__listItem {
    padding: 0.7rem 0;

    @include media-breakpoint-up(md) {
        padding: 0;
    }
}
