.s-input {
    .s-input__container {
        cursor: pointer;
        display: block;
        position: relative;
        padding-left: 2.5rem;
        user-select: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .s-input__container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .s-input__checkmark {
        position: absolute;
        top: 0.3rem;
        left: 0;
        height: 1.5rem;
        width: 1.5rem;
        background-color: $color-white;
        border: 1px solid $color-black;

        @include media-breakpoint-up(xl) {
            top: 0.4rem;
        }
        @include media-breakpoint-up(xxl) {
            top: 0.6rem;
        }
    }

    .s-input__container:hover input ~ .s-input__checkmark {
        background-color: $color-white;
    }

    .s-input__checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .s-input__container input:checked ~ .s-input__checkmark:after {
        display: block;
    }

    .s-input__container .s-input__checkmark:after {
        display: none;
        width: 2rem;
        height: 2rem;
        background: url('../images/myh-icon_check.svg');
        z-index: 999;
        left: -0.4rem;
        top: -0.4rem;
    }

    label {
        margin-bottom: 1.5rem;
    }
}

