.s-cms-content--download-elements {

    .s-download-teaser__title {
        margin-top: 1.2rem;
        margin-bottom: 0;
    }

    .s-cms-content__carousel {
        .loading-mask .loader > img {
            max-width: 5.8rem;
        }
        .loading-mask .loader > img,
        .loading-mask {
            position: absolute;
        }
    }
}

.s-download-content {
    .s-download-content__info {
        display: flex;

        .s-download-content__image {
            flex: 0 1 6rem;
            margin-right: 1.6rem;

            img {
                display: block;
            }
        }

        .s-h5 {
            margin: 0;
        }
    }

    .s-download-content__text {
        margin-top: 2.4rem;
    }

    .s-download-content__section-title {
        border-bottom: 1px solid $border-color;
        @include lib-font-size($font-size__s);
        font-weight: $font-weight__base;
        padding: 1rem 0;
        margin: 2.4rem 0 1.6rem;
    }

    .s-download-content__title {
        margin-bottom: 2.4rem;
    }

    .s-download-content__item {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 0;

        a {
            white-space: nowrap;
            margin-left: 1.6rem;
        }
    }
}
