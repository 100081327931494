.s-cms-content--image-gallery > div {
    column-count: 2;
    column-gap: 0.5rem;

    @include media-breakpoint-up(md) {
        column-gap: 0.6rem;
        column-count: 3;
    }
    @include media-breakpoint-up(lg) {
        column-count: 4;
    }

    .s-gallery-item {
        margin-bottom: 0.5rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 0.6rem;
        }

        a,
        img {
            display: block;
        }
    }
}
