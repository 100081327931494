$page-opener__brand-icon__size: 7rem;
$page-opener__brand-icon__size-md: 9.6rem;

$page-opener__edge__size: 2.6rem;

.s-page__opener-content {

    div:not(.swiper-initialized) > .s-page__opener-carousel {
        position: relative;

        .s-page-opener-wrapper + .s-page-opener-wrapper {
            position: absolute;
            width: 100%;
            z-index: -1;
            top: 0;
            left: 0;
            opacity: 0;
        }
    }
}

.s-cms-content-image-full,
.s-page-opener {
    position: relative;
}

.s-cms-content-image-full__media,
.s-page-opener__media {
    img {
        display: block;
    }
}

.s-cms-content-image-full__subline,
.s-page-opener__subline {
    @include lib-css(font-weight, $font-weight__medium);
    @include lib-font-size($h2__font-size);
    @include lib-css(line-height, 28px);
    margin: 0;

    @include media-breakpoint-up(md) {
        @include lib-css(line-height, 32px);
    }
    @include media-breakpoint-up(lg) {
        @include lib-css(line-height, 36px);
    }
    @include media-breakpoint-up(xl) {
        @include lib-css(line-height, 40px);
    }
}

.s-page-opener__global-action {
    top: 90%;
    position: absolute;
    min-width: 80%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    @include media-breakpoint-up(md) {
        bottom: 3rem;
        left: 0;
        right: 0;
        transform: unset;
        top: unset;
        min-width: unset;
    }
}

.s-page-opener__brand-icon {

    .s-icon {
        margin: 0 auto;
        width: $page-opener__brand-icon__size;
        height: $page-opener__brand-icon__size;
        display: block;

        @include media-breakpoint-up(lg) {
            width: $page-opener__brand-icon__size-md;
            height: $page-opener__brand-icon__size-md;
        }
    }
}

// PORTRAIT
.s-page-opener--portrait {
    .s-page-opener__content {
        padding: 1.6rem 1.6rem 2.4rem;

        @include media-breakpoint-up(md) {
            padding: 1.6rem 10%;
        }

        @include media-breakpoint-up(lg) {
            padding: 2.9rem 10%;
        }

        .s-cms-content__action {
            margin-top: 2.4rem;
        }
    }

    &.s-page-opener--image-pos-right {
        .s-page-opener__brand-icon {

            @include media-breakpoint-down(sm) {
                top: 100%;
                bottom: unset;
            }
        }
    }

    .s-page-opener__brand-icon {

        @include media-breakpoint-down(sm) {
            transform: translateX(-50%);
            position: absolute;
            bottom: 100%;
            top: unset;
            left: 50%;
        }

        .s-icon {

            @include media-breakpoint-up(md) {
                width: $page-opener__brand-icon__size;
                height: $page-opener__brand-icon__size;
            }
            @include media-breakpoint-up(lg) {
                width: $page-opener__brand-icon__size-md;
                height: $page-opener__brand-icon__size-md;
            }
        }
    }

    .s-page-opener__scroll-container {
        max-height: 15rem;
        position: relative;
        overflow: auto;

        > div {
            padding-bottom: $page-opener__edge__size;
        }

        @include media-breakpoint-up(lg) {
            max-height: 20rem;
        }

        @include media-breakpoint-up(xl) {
            max-height: 25rem;
        }

        @include media-breakpoint-up(xxl) {
            max-height: 38rem;
        }

        &._scrollable + .s-page-opener__scroll-container-edge {
            width: 100%;
            height: $page-opener__edge__size;
            margin-top: -$page-opener__edge__size;
            position: relative;
        }


        // stylelint-disable
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
        // stylelint-enable
    }
}

// FULL / VIDEO / SPLIT
.s-cms-content-image-full,
.s-page-opener--video,
.s-page-opener--split,
.s-page-opener--full {

    .s-page-opener__brand-icon {
        position: absolute;
        top: 0;
        left: 2.4rem;

        @include media-breakpoint-up(md) {
            top: 0;
            right: 2.3rem;
            left: auto;
        }
        @include media-breakpoint-up(lg) {
            right: 3.3rem;
        }

        &--logo-m5 {
            left: 0;

            @include media-breakpoint-up(md) {
                top: 0.6rem;
                left: auto;
                right: 0;
            }
            @include media-breakpoint-up(lg) {
                top: 1.2rem;
                right: 0.1rem;
            }
        }
    }

    .s-cms-content-image-full__text,
    .s-page-opener__text {
        padding: 0 2rem;
        position: absolute;
        width: calc(100% - 4rem);
        text-align: center;
        top: 0;
        left: 0;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .page-title {

            @include media-breakpoint-up(md) {
                max-width: 60rem;
                margin: 0 auto;
            }
            @include media-breakpoint-up(lg) {
                max-width: 81rem;
            }
            @include media-breakpoint-up(xxl) {
                max-width: 113rem;
            }
        }
    }

    &.s-cms-content-image-full--button-pos-standard_above,
    &.s-cms-content-image-full--button-pos-centered_above,
    &.s-cms-content-image-full--button-pos-above,
    &.s-page-opener--button-pos-standard_above,
    &.s-page-opener--button-pos-centered_above,
    &.s-page-opener--button-pos-above {

        .s-cms-content-image-full__text,
        .s-page-opener__text {

            .s-cms-content-image-full__text-inner,
            .s-page-opener__text-inner {
                position: relative;
            }

            .s-cms-content__action-wrapper {
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                transform: translateY(1.6rem);
                display: flex;
                justify-content: center;

                .s-cms-content__action {
                    display: inline-block;
                }
            }
        }
    }

    &.s-cms-content-image-full--button-pos-standard_below,
    &.s-cms-content-image-full--button-pos-centered_below,
    &.s-cms-content-image-full--button-pos-below,
    &.s-page-opener--button-pos-standard_below,
    &.s-page-opener--button-pos-centered_below,
    &.s-page-opener--button-pos-below {

        .s-cms-content-image-full__text,
        .s-page-opener__text {

            .s-cms-content__action-wrapper {
                // parent is 80% height so 4/5 of opener height,
                // so 100% top is 80% of opener height
                // + 12.5% which is 1/2 of one quarter of the parent
                // which is equal to 1/2 of the 1/5 left of the opener
                top: 112.5%;
                position: absolute;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }

        }

        @include media-breakpoint-up(md) {
            .s-cms-content-image-full__text,
            .s-page-opener__text {

                .s-cms-content-image-full__text-inner,
                .s-page-opener__text-inner {
                    position: relative;
                }

                .s-cms-content__action-wrapper {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    right: 0;
                    transform: translateY(1.6rem);
                    display: flex;
                    justify-content: center;

                    .s-cms-content__action {
                        display: inline-block;
                    }
                }
            }
        }
    }
}

// FULL
.s-cms-content-image-full,
.s-page-opener--full {

    @include media-breakpoint-up(md) {

        .s-cms-content-image-full__text,
        .s-page-opener__text {
            position: absolute;
            width: 45%;
            left: 5%;
            text-align: left;

            .page-title {
                text-align: left;
            }
        }

        &.s-cms-content-image-full--button-pos-standard_below,
        &.s-cms-content-image-full--button-pos-centered_below,
        &.s-cms-content-image-full--button-pos-below,
        &.s-cms-content-image-full--button-pos-standard_above,
        &.s-cms-content-image-full--button-pos-centered_above,
        &.s-cms-content-image-full--button-pos-above,
        &.s-page-opener--button-pos-standard_below,
        &.s-page-opener--button-pos-centered_below,
        &.s-page-opener--button-pos-below,
        &.s-page-opener--button-pos-standard_above,
        &.s-page-opener--button-pos-centered_above,
        &.s-page-opener--button-pos-above {

            .s-cms-content-image-full__text,
            .s-page-opener__text {

                .s-cms-content__action-wrapper {
                    right: auto;
                }
            }
        }
    }
}

// VIDEO
.s-page-opener--video {
    video {
        display: block;
    }
}

// SPLIT
.s-page-opener--split {

    &.s-page-opener--button-pos-centered_above {

        .s-page-opener__text {
            .s-cms-content__action-wrapper {
                display: flex;
                justify-content: space-around;

                @include media-breakpoint-up(xs) {
                    min-width: 0;
                }
            }
        }
    }

    &.s-page-opener--button-pos-standard_above {

        .s-page-opener__text {
            .s-cms-content__action-wrapper {
                display: flex;
                justify-content: center;

                .s-cms-content__action {
                    margin: 0 4rem;
                }
            }
        }
    }

    &.s-page-opener--button-pos-standard_above,
    &.s-page-opener--button-pos-centered_above {

        &.s-page-opener--with-global-button {
            .s-page-opener__text {
                // buttons are not suppose to be visible starting sm (only global button visible)
                // hiding buttons at md already because they collide with global button
                @include media-breakpoint-down(md) {
                    .s-cms-content__action-wrapper {
                        display: none;
                    }
                }
            }
        }

        .s-page-opener__text {

            @include media-breakpoint-down(xs) {
                .s-cms-content__action-wrapper {

                    .s-cms-content__action {

                        > .action {
                            width: auto;
                            min-width: 23rem;
                            display: inline-flex;
                        }
                    }
                }
            }
            @include media-breakpoint-down(sm) {

                .page-title {
                    padding: 0 2rem;
                }

                .s-cms-content__action-wrapper {
                    position: unset;
                    margin-top: 0;
                    top: unset;
                    left: unset;
                    flex-direction: column;
                    gap: 1rem;

                    .s-cms-content__action {
                        margin: 0;
                    }
                }

                .s-page-opener__text-inner {
                    position: static;
                }
            }

            .s-cms-content__action-wrapper {
                width: 100%;

                .s-cms-content__action {
                    display: inline-block;
                }
            }
        }
    }
}
