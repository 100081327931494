@mixin s-icon-background($icon, $size: 'md') {
    background: map-get($icon-image, $icon) no-repeat center transparent;
    background-size: map-get($icon-size, $size);
    height: map-get($icon-size, $size);
    width: map-get($icon-size, $size);
    display: inline-block;
}

@mixin s-icon-background-before($icon, $size: 'md', $srOnly: true) {
    &::before {
        content: '';
        @include s-icon-background($icon, $size);
    }

    @if $srOnly {
        * {
            @include sr-only();
        }
    }
}

@mixin s-icon-background-after($icon, $size: 'md', $srOnly: true) {
    &::after {
        content: '';
        @include s-icon-background($icon, $size);
    }

    @if $srOnly {
        * {
            @include sr-only();
        }
    }
}
