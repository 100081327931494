.grand.totals {
    border-top: 2px solid $summary-totals-color;
    border-bottom: 2px solid $color-black;
}

.abs-discount-block > .title,
.cart-summary .block > .title,
.paypal-review-discount .block > .title {
    border-top: none;
}

.abs-sidebar-totals .amount strong,
.cart-totals .amount strong,
.opc-block-summary .table-totals .amount strong {
    font-weight: $font-weight__semibold;
}

.abs-sidebar-totals .mark,
.cart-totals .mark,
.opc-block-summary .table-totals .mark {
    font-weight: $font-weight__base;
    @include lib-font-size();
    padding-left: 0;
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
}

.abs-sidebar-totals .amount,
.cart-totals .amount,
.opc-block-summary .table-totals .amount {
    padding-right: 0;
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
}

.abs-sidebar-totals,
.cart-totals,
.opc-block-summary,
.abs-sidebar-totals,
.cart-totals,
.opc-block-summary {
    tr:nth-last-child(2) {
        .mark,
        .amoumt {
            padding-bottom: 2rem;
        }
    }
}

.abs-sidebar-totals .grand .mark,
.cart-totals .grand .mark,
.opc-block-summary .table-totals .grand .mark,
.abs-sidebar-totals .grand .amount,
.cart-totals .grand .amount,
.opc-block-summary .table-totals .grand .amount {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.abs-sidebar-totals .grand .mark,
.cart-totals .grand .mark,
.opc-block-summary .table-totals .grand .mark,
.abs-sidebar-totals .grand .amount,
.cart-totals .grand .amount,
.opc-block-summary .table-totals .grand .amount {
    padding-top: 1.5rem;
    padding-bottom: 1.2rem;
}


.abs-sidebar-totals .mark,
.cart-totals .mark,
.opc-block-summary .table-totals .mark {
    padding-left: 0;
}

.abs-sidebar-totals .amount,
.cart-totals .amount,
.opc-block-summary .table-totals .amount {
    padding-right: 0;
}

.abs-sidebar-totals .grand .amount strong,
.cart-totals .grand .amount strong,
.opc-block-summary .table-totals .grand .amount strong,
.abs-sidebar-totals .mark strong,
.cart-totals .mark strong,
.opc-block-summary .table-totals .mark strong,
.abs-sidebar-totals .grand .amount strong,
.cart-totals .grand .amount strong,
.opc-block-summary .table-totals .grand .amount strong,
.cart-totals .grand .amount strong,
.opc-block-summary .table-totals .grand .amount strong,
.abs-sidebar-totals .mark strong,
.cart-totals .mark strong,
.opc-block-summary .table-totals .mark strong,
.cart-totals .mark strong,
.opc-block-summary .table-totals .mark strong {
    font-weight: $font-weight__semibold;
}

.abs-product-link,
.product-item-name,
.product.name a {
    font-weight: $font-weight__base;
}

@include media-breakpoint-up(md) {
    .column:not(.sidebar-additional) form .actions-toolbar,
    .column:not(.sidebar-main) form .actions-toolbar {
        margin-left: 0;
    }
}
