.s-checkout-form-login__wrapper {
    padding: 3rem;
    margin-bottom: 3rem;
    background-color: $color-checkout-email-fieldset;
}

.column:not(.sidebar-additional) form.s-checkout-form-login .actions-toolbar,
.column:not(.sidebar-main) form.s-checkout-form-login .actions-toolbar {
    margin-left: 0;
    margin-top: 3rem;

    @include media-breakpoint-up(sm) {
        .action {
            margin-right: 3rem;
        }
    }
}

.s-checkout-form-login__note {
    display: block;
    margin-top: 1rem;

    @include media-breakpoint-up(md) {
        margin-top: 0;
    }
}

.s-checkout-form-login__actions-toolbar {
    display: flex;
    align-items: center;
}

.s-checkout-form-login__input-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.s-checkout-form-login__fieldset {
    margin-bottom: 1.6rem;
}


// DESKTOP
@include media-breakpoint-up(md) {
    .s-checkout-form-login__fieldset {
        width: calc(50% - 15px);
        margin-bottom: 2.4rem;
    }

    .s-checkout-form-login__input-wrapper {
        flex-direction: row;
        justify-content: space-between;
    }

}
