.s-checkout-summary {
    background-color: $page__background-color;
    box-shadow: $box-shadow;

    &._show {
        left: 0;
        width: 100%;
        max-width: 100%;
    }

    .modal-header {
        height: 1rem;

        .action-close {
            margin: 0.5rem;
            z-index: 2;

            &::before {
                color: $color-black;
            }
        }
    }

    .page-title {
        margin-top: 0;
    }

    .modal-content {
        border: none;
    }

    .minicart-items-wrapper {
        margin-top: 3rem;
        padding-top: 0;
    }

    .opc-block-summary {
        margin-bottom: 0;

        .s-h4 {
            line-height: 1;
        }
    }

    .opc-block-shipping {
        margin-top: 2rem;
    }

    .opc-block-summary,
    .modal-inner-wrap {
        background-color: $page__background-color;
    }

    .product-item-details {
        .details-qty {
            margin-top: 0;
        }
    }

    .product-item-name-block {
        max-width: 12.7rem;
        word-break: break-word;
    }

    .s-summary-details {
        width: 100%;
    }

    .details-qty {
        margin-top: 0;
    }

    .discount-code {
        .s-button-link {
            margin-top: 1.2rem;
        }
    }

    @include media-breakpoint-up(lg) {
        width: calc(100% / 12 * 4);
        margin-top: -5rem;
        padding-bottom: 4rem;
    }
}

.checkout-shipping-method .actions-toolbar > .primary {
    float: left;
}

.s-checkout-step-content__actions-toolbar {
    margin-top: 4rem;

    .primary {
        width: 29rem;
    }
}

@include media-breakpoint-up(lg) {
    .column:not(.sidebar-additional) form .actions-toolbar,
    .column:not(.sidebar-main) form .actions-toolbar {
        margin-left: 0;
        padding-left: 0;
    }

    .checkout-billing-address .actions-toolbar {
        display: flex;
        max-width: 50rem;
        justify-content: center;
        text-align: center;
    }
}

.field-tooltip._active .field-tooltip-content {
    transform: translateX(-120%);

    @include media-breakpoint-up(md) {
        transform: translate(-120%, -2rem);
    }
}

.field-tooltip .field-tooltip-content:before,
.field-tooltip .field-tooltip-content:after {
    right: -20px;
    left: unset;
    top: 35px;
    transform: rotate(180deg);
}
