.s-product-brand {

    & + .s-product-info-main {
        --product-brand-color: #{$text__color};
        --product-brand-color--hover: #{$button-primary__hover__background};

        .s-wishlist-icon {
            fill: var(--product-brand-color);
        }

        .s-product-detail-addtocart-sticky__title,
        .s-product-detail--title h1 {
            color: var(--product-brand-color);
        }

        .s-product-detail-addtocart-sticky__prize,
        .product-info-price {

            .old-price,
            .normal-price:not(.special-price) {
                color: var(--product-brand-color);
            }
        }

        .s-product-detail-addtocart-sticky__container-buttons,
        .product-add-form {

            .action.primary {
                @include lib-button-revert-secondary-color(
                    $button-primary__color, var(--product-brand-color), 1px solid var(--product-brand-color),
                    $button-primary__hover__color, var(--product-brand-color--hover), 1px solid var(--product-brand-color--hover),
                    $button-primary__active__color, var(--product-brand-color--hover), 1px solid var(--product-brand-color--hover)
                );
            }

            .action.secondary {
                @include lib-button-revert-secondary-color(
                    var(--product-brand-color), $button__background, 1px solid var(--product-brand-color),
                    $button-primary__hover__color, var(--product-brand-color--hover), 1px solid var(--product-brand-color--hover),
                    $button-primary__active__color, var(--product-brand-color--hover), 1px solid var(--product-brand-color--hover)
                );
            }

        }
    }


}

.s-product-brand--meyer-exclusive {

    & + .s-product-info-main {
        --product-brand-color: #{$color-exclusive};
        --product-brand-color--hover: #{$color-exclusive--dark};
    }
}

.s-product-brand--meyer-active,
.s-product-brand--golf {

    & + .s-product-info-main {
        --product-brand-color: #{$color-golf};
        --product-brand-color--hover: #{$color-golf--dark};
    }
}

.s-product-brand--m-5 {

    & + .s-product-info-main {
        --product-brand-color: #{$color-m5};
        --product-brand-color--hover: #{$color-m5--dark};
    }
}
