.s-customer-login {
    justify-content: center;
    max-width: 100rem;
    margin: 2rem auto 0 auto;

    .col:first-of-type {
        @include media-breakpoint-up(md) {
            margin-right: 5rem;
        }

        @include media-breakpoint-up(lg) {
            margin-right: 12rem;
        }
    }

    .block .block-title {
        border-bottom: none;
    }

    .s-customer-login__new-customer.block .block-title {
        padding-bottom: 0;
    }

    .s-customer-login__new-customer,
    .s-customer-login__login {
        width: 100%;
        float: unset;
    }

    .actions-toolbar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .login.primary {
            margin-bottom: 1.6rem;
        }
    }

    .fieldset:after {
        text-align: center;
        color: $font__color;
        margin-top: 1rem;
        @include lib-font-size($font-size__s);
    }
}

.s-customer-login__login,
.s-customer-login__new-customer {
    .block-title {
        .s-h3 {
            margin: 0;
        }
    }
}

.s-customer-login__login {
    margin-bottom: 0;

    .field {
        margin-bottom: 2.4rem;
    }

    .actions-toolbar {
        margin-top: 4rem;
    }

}

.s-customer-login__new-customer {
    margin-top: 4rem;
    box-sizing: border-box;
    padding: 4rem 2rem;
    box-shadow: $box-shadow;

    @include media-breakpoint-up(md) {
        margin-top: 0;
        padding: 4rem;
    }

    .block-content,
    .block-title {
        text-align: center;
    }

    .block-content {
        p {
            margin-bottom: 1.6rem;
        }
    }
}

.s-actions-toolbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .secondary {
        margin-top: 1.6rem;
    }

    @include media-breakpoint-up(md) {
        justify-content: flex-start;
        flex-direction: row;

        .secondary {
            margin-top: 0;
            margin-left: 2rem;
        }
    }
}
