.messages {
    .s-icon {
        display: none;
    }
}

.message {
    border: 2px solid;
    display: none;
    text-align: center;

    .s-icon {
        width: 2.4rem;
        height: 2.4rem;
        display: none;
        margin-bottom: -0.6rem;
    }


    &.success,
    &.notice,
    &.info,
    &.warning,
    &.error {
        display: block;
        margin: 2rem 0;
        @include lib-font-size();
        font-weight: $font-weight__base;
        padding: 1.1rem;

        .s-icon {
            display: inline-block;
            fill: $color-black;
        }
    }
}

.message.success > *:first-child:before,
.message.error > *:first-child:before,
.message.warning > *:first-child:before,
.message.notice > *:first-child:before {
    display: none;
}
