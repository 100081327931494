.fotorama--fullscreen {

    .fotorama__arr {
        background-color: transparent;

        &:hover,
        &:focus,
        &:active {
            background-color: transparent;
        }
    }

    .fotorama__fullscreen-icon {
        &:hover,
        &:focus,
        &:active {
            background-color: transparent;
            outline: 0 none;

            &::after {
                display: none;
            }
        }
    }

    .fotorama__nav-wrap--vertical.fotorama__nav-wrap {
        left: 1.7rem;
    }
}

.fotorama {
    .fotorama__arr--disabled {
        opacity: 0;
    }
}

.fotorama:not(.fotorama--fullscreen) {
    overflow: visible;

    .fotorama__nav-wrap--vertical.fotorama__nav-wrap {
        left: calc(-85px - 17px);
    }

    .fotorama__nav-wrap--vertical .fotorama__nav__shaft {
        margin-top: -1.7rem;
    }

    .fotorama__nav-wrap--vertical .fotorama__thumb__arr--left .fotorama__thumb--icon {
        transform: rotate(-90deg);
    }

    .fotorama__nav-wrap--vertical .fotorama__thumb__arr--left .fotorama__thumb--icon,
    .fotorama__nav-wrap--vertical .fotorama__thumb__arr--right .fotorama__thumb--icon {
        width: 4.8rem;
        height: 4.8rem;
        margin: 0;
        padding: 0;
        background-position: center center;
    }

    // sass-lint:disable no-important
    .fotorama__stage {
        left: 0 !important;
        overflow: hidden;
    }

    .fotorama__stage__shaft {
        max-width: 100% !important;
    }
    // sass-lint:enable  no-important
}
