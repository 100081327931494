.s-cms-content--video {

    .s-cms-content__text {
        p {
            margin-bottom: 0;
            margin-top: 2.4rem;
            text-align: center;
        }
    }
}
