//
//  Responsive variables
//  _____________________________________________

$use-flex    : true !default; // Use flexbox [true|false]
$responsive  : true !default; // Theme is responsive [true|false]

$screen__xxs : 0 !default;
$screen__xs  : 414px !default;
$screen__s   : 576px !default;
$screen__m   : 768px !default;
$screen__l   : 992px !default;
$screen__xl  : 1200px !default;
$screen__xxl  : 1600px !default;

$screen-breakpoints: (
    xxs: $screen__xxs,
    xs: $screen__xs,
    sm: $screen__s,
    md: $screen__m,
    lg: $screen__l,
    xl: $screen__xl,
    xxl: $screen__xxl
) !default;
