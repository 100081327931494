.s-cms-content--info-bar {
    text-align: center;

    @include media-breakpoint-up(md) {
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
    }

    .s-cms-content__headline {
        margin-bottom: 1.6rem;
    }

    p {
        margin-bottom: 1.8rem;
        text-align: center;
    }

    .action {
        margin: 0 auto;
    }
}
