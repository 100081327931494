.fotorama__dot {
    display: block;
    position: relative;
    top: 12px;
    left: 6px;
    width: 4px;
    height: 4px;
    border: 1px solid;
    border-radius: 50%;
    background-color: $dot-background-color;
    border-color: $dot-border-color;
    opacity: 0.8;

    .fotorama__nav__frame--dot:focus & {
        box-shadow: none;
    }

    .fotorama__nav__frame--thumb:focus &:after,
    .fotorama__nav__frame--dot:focus &:after {
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
    }

    .fotorama__active & {
        background-color: $dot-active-background-color;
        border-color: $dot-active-border-color;
        width: 6px;
        height: 6px;
        top: 11px;
        left: 5px;
    }
}
