.product-items {
    margin-top: 0;
}

@include media-breakpoint-up(md) {
    .page-layout-2columns-left {
        .column.main {
            width: 74%;
        }

        .sidebar-main {
            width: 25%;
        }
    }
}

@include media-breakpoint-down(sm) {
    .page-layout-2columns-left {
        .sidebar-main {
            position: fixed;
            top: 0;
            z-index: $zindex-fixed;
            bottom: 0;
            left: 0;
            right: 0;
            background: white;
            padding: 2rem;
            transition: transform 0.4s;
            overflow: scroll;
            transform: translateX(100%);

            &._show {
                transform: translateX(0);
            }
        }

    }
}
