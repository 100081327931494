.s-cms-content--teaser-half-size {

    .s-cms-content__text {
        p {
            margin-bottom: 1.6rem;
        }
    }

    img {
        display: block;
    }

    .s-cms-content__headline {
        margin-bottom: 1.1rem;
    }

    .c-cms-content__info-container {

        &.s-cms-content--media-pos-right,
        &.s-cms-content--media-pos-left {
            margin-top: 2.4rem;
        }
    }
}
