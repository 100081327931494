.s-filter-options-item:hover {
    .s-filter-options__arrow {
        transform: translateY(4px);
    }
}

.filter-options .filter-options-title {
    cursor: pointer;
    margin-bottom: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-weight: 500;
    @include lib-font-size();

    &:hover {
        text-decoration: underline;
    }
}

.filter-options .filter-options-content {
    margin-bottom: 1.8rem;
    margin-top: -0.5rem;

    label {
        @include lib-css(cursor, pointer);
        line-height: 1.6;

        @include media-breakpoint-up(sm) {
            line-height: 1.5;
        }
        @include media-breakpoint-up(xl) {
            line-height: 1.8;
        }
        @include media-breakpoint-up(xxl) {
            line-height: 1.5;
        }
    }

    a {
        color: $font__color;

        &:hover {
            text-decoration: none;
        }
    }
}

.filter-current {
    ol.items {
        display: flex;
        flex-wrap: wrap;
    }

    li.item {
        margin-right: 3rem;
        position: relative;
    }
}
