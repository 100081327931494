.s-teaser-responsible-fashion {
    background: url(../images/meyer-responsible-fashion.jpg) 0 0 no-repeat #ffffff;
    background-position: center center;
    color: #ffffff;
    text-align: center;
    padding: 6.5rem 2.4rem;
    margin-bottom: 3.4rem;

    @include media-breakpoint-up(md) {
        margin-bottom: 6.4rem;
    }

    p {
        max-width: 60rem;
        margin-left: auto;
        margin-right: auto;
    }

    .button.primary {
        margin: 0 auto;
    }
}

.s-teaser-responsible-fashion__logo {
    background: url(../images/myh-icon_nachhaltigkeit.svg) 0 0 no-repeat transparent;
    background-size: cover;
    margin: 0 auto;
    width: 5rem;
    height: 5rem;
    margin-bottom: 1.8rem;
}

