$grid-breakpoints: $screen-breakpoints;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

$theme-colors: $color-themes;
$colors: $color-themes;

@each $color, $value in $theme-colors {
    .bg-gradient-#{$color} {
        background: $value;
        background: linear-gradient(180deg, transparent, $value) repeat-x !important;
    }
}
