.checkout-payment-method .payment-option._collapsible .payment-option-content {
    // sass-lint:disable no-important
    display: block !important;
    // sass-lint:enable no-important
    padding-left: 0;

    .input-text {
        max-width: 25rem;
    }

    .actions-toolbar {
        margin-left: 0;
    }
}

.checkout-payment-method .payment-method + .payment-method .payment-method-title {
    border-top: none;
}

.checkout-payment-method .payment-methods {
    margin-left: 0;
    margin-right: 0;
}

.checkout-payment-method .field-select-billing {
    max-width: unset;
}

.checkout-payment-method .payment-method-title {
    padding-left: 0;
    padding-right: 0;
}

.payment-method:first-of-type {
    margin-top: 2rem;
}

#amazon-payment {

    .payment-method-content {
        padding-left: 2.5rem !important;
    }
}

.payment-method-content {
    // sass-lint:disable no-important
    padding-left: 0 !important;
    // sass-lint:enable no-important

    button.primary {
        @include lib-button-primary();
        @include lib-payment-button();
    }

    .amazon-button-container {
        margin-left: 5.6rem;
        text-align: left;

        + .checkout-agreements-block {
            font-size: 16px;

            * {
                box-sizing: border-box;
            }
        }
    }

    .amazon-payment-action-container .action-edit-payment {
        margin-left: 5.2rem;
        margin-bottom: 1.6rem;
    }

    .checkout-billing-address {
        padding-left: 3.8rem;

        .billing-address-same-as-shipping-block {
            display: flex;

            label {
                padding-left: 3rem;
                margin-top: -0.4rem;
            }
        }

        .billing-address-details {
            padding-left: 3rem;
        }
    }

    .actions-toolbar {
        display: flex;
        padding-left: 3.8rem;
        margin-top: 2.3rem;

        .action-update span {
            margin: 0 auto;
        }

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }

    .payment-method-billing-address {
        padding-left: 1.7rem;
    }

    .form {
        // sass-lint:disable no-important
        padding-left: 0 !important;
        // sass-lint:enable no-important
    }
}

.heidelpayUI.input {

    .heidelpayInput {
        // sass-lint:disable no-important
        outline: none !important;
        padding-top: 0.7rem !important;
        box-shadow: none !important;
        padding-bottom: 0.6rem !important;
        border: $form-element-input__border !important;
        border-radius: $form-element-input__border-radius !important;
        // sass-lint:enable no-important
        @include lib-font-size();
    }
}

.heidelpayUI.primary {
    // sass-lint:disable no-important
    margin-left: 3.8rem !important;

    &:hover {
        border: none;
    }

    // sass-lint:enable no-important
}

form.unzerUI.form,
form.heidelpayUI.form {
    padding-left: 4rem !important;

    #card-element-id-number,
    #sepa-direct-debit-iban-field,
    .checkout-agreements-block .checkout-agreement,
    .two.fields,
    .unzerUI.form,
    .heidelpayUI.form {
        padding-left: 4.5rem !important;

        .two.fields {
            padding-left: 0 !important;
        }
    }

    .unzerUI.form,
    .heidelpayUI.form {

        .eight.wide.field {
            padding: 0;
        }
    }
}

.heidelpayUI.form .field > label {
    // sass-lint:disable no-important
    margin-top: -0.4rem !important;
    // sass-lint:enable no-important
}

.heidelpayChoices,
.heidelpayUI {
    // sass-lint:disable no-important
    font-size: $font-size__base__xxs !important;

    @include media-breakpoint-up(xs) {
        font-size: $font-size__base__xs !important;
    }

    @include media-breakpoint-up(md) {
        font-size: $font-size__base !important;
    }
    @include media-breakpoint-up(xxl) {
        font-size: $font-size__xl !important;
    }

    // sass-lint:enable no-important
}

.heidelpayUI.form .error.message.s-checkout-agreement-error {
    // sass-lint:disable no-important
    min-height: unset !important;
    border-radius: 2.5rem !important;
    margin: 2rem 0 !important;
    padding: 1rem 2rem !important;
    font-weight: 500 !important;
    line-height: 1.2em !important;
    background: transparent !important;
    border: 2px solid $alert-color__danger !important;
    color: #000 !important;
    position: relative !important;
    // sass-lint:enable no-important
}

.payment-method-content.stripe-payments-card-form .fieldset.ccard,
.payment-method-note {
    padding-left: 5.5rem;
}

#sepa-direct-debit-iban-field {
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.sepa-direct-debit-mandate {
    @include lib-font-size($font-size__s);

    h3 {
        @include lib-font-size($font-size__m);
        margin-bottom: 2rem;
    }

    p {
        margin-bottom: 1rem;
    }
}
