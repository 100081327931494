.s-cms-content-section--faq + .s-cms-content-section--faq {
    margin-top: 0;

    @include media-breakpoint-down(sm) {
        .s-cms-content {
            padding-top: 0;
        }
    }
}

.s-cms-content--faq {

    .s-cms-content__items {
        border-top: 1px solid $color-grayD7;

        .s-faq-item {
            border-bottom: 1px solid $color-grayD7;
        }

        .s-faq-item__button {
            padding-top: 1.2rem;
            padding-bottom: 1.2rem;
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            text-align: left;

            @include media-breakpoint-up(md) {
                padding-top: 2rem;
                padding-bottom: 2rem;
            }

            .s-icon {
                margin-left: 0.5rem;
                width: $icon-size-sm;
                height: $icon-size-sm;
            }

            &._active {
                .s-icon {
                    transform: rotate(-180deg);
                }
            }

            .s-h5 {
                margin: 0;
            }
        }

        .s-faq-item__content {
            padding-bottom: 2.4rem;

            @include media-breakpoint-up(md) {
                padding-bottom: 3.2rem;
            }

            p:last-child {
                margin: 0;
            }
        }
    }
}

