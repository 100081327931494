.s-catalog-teaser {
    cursor: pointer;

    @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;

        .swatch-attribute-options {
            margin-top: 0;
        }
    }

    .s-catalog-teaser__brands {
        position: absolute;
        z-index: 2;
        top: 1.1rem;
        left: 1.1rem;
        display: flex;

        @include media-breakpoint-up(md) {
            top: 1.3rem;
            left: 1.3rem;
        }

        img {
            object-fit: contain;
            width: 2.9rem;
            height: 2.4rem;

            @include media-breakpoint-up(md) {
                width: 3.8rem;
                height: 2.4rem;
            }
        }
    }

    .price-box {
        .price-label {
            display: block;

            @include media-breakpoint-up(xl) {
                white-space: nowrap;
            }

            @include media-breakpoint-down(md) {
                font-size: $font-size__xs;
            }
        }

        &.price-final_price {
            margin: 0;
            flex-direction: column-reverse;
            align-items: flex-end;
            text-align: right;

            .normal-price {
                margin-top: 0.5rem;

                &.special-price {
                    margin-top: 0;
                }
            }

            .old-price {
                margin-right: 0;
                margin-top: 0.5rem;

                .price {
                    font-weight: $font-weight__medium;
                }
            }

            .price {
                font-weight: $font-weight__medium;
            }
        }
    }

    .price-box.price-final_price .old-price,
    .price-box.price-final_price .normal-price,
    .price-box.price-final_price .price,
    .s-catalog-teaser__name {
        line-height: 1.7rem;
        @include lib-font-size();

        @include media-breakpoint-up(sm) {
            line-height: 2rem;
        }

        @include media-breakpoint-up(xxl) {
            line-height: 2.4rem;
        }
    }

    .swatch-option.color {
        --swatch-option__size: 1.6rem;

        &.swatch-with-border {
            --swatch-option__size: 1.4rem;
        }
    }

    .swatch-option.color {
        margin: 0 1.4rem 1rem 0;

        &::after {
            border-width: 1px;
            width: 0.6rem;
            height: 0.6rem;
            min-width: 0.6rem;
            max-width: 0.6rem;
            min-height: 0.6rem;
            max-height: 0.6rem;
        }
    }

    .s-catalog-teaser__color-count {
        font-size: $font-size__s;
        line-height: $line-height__base;
    }

    .s-catalog-teaser__price {
        padding-left: 1rem;
    }
}

.s-catalog-teaser__details {
    display: flex;
    justify-content: space-between;
}

.product-item-details--button-active {
    &:not(.s-catalog-teaser--no-cart) {
        .s-catalog-teaser__wrapper {

            &:hover {
                .actions-primary {
                    opacity: 1;
                }

                .s-badges {
                    opacity: 0;
                }
            }
        }
    }
}

.s-catalog-teaser__wrapper {
    position: relative;

    .product-item-name {
        margin: 0;
    }

    .product-item-actions {
        margin: 0;
    }

    .actions-primary {
        pointer-events: visible;
        opacity: 0;
        position: absolute;
        bottom: 2.4rem;
        transition: opacity 0.4s;
        left: 1rem;
        right: 1rem;
        z-index: 3;

        .action.tocart {
            width: 100%;

            @include media-breakpoint-only(lg) {
                font-size: $font-size__m;

                .s-icon-container {
                    padding-left: $icon-size-button;
                }
            }
        }
    }

    .action.towishlist {
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
        z-index: 3;
    }

    .action.tocompare {
        display: none;
    }

    .s-badges {
        transition: opacity 0.4s;
        z-index: 2;
        left: 0.75rem;
        top: auto;

        @include media-breakpoint-down(sm) {
            bottom: 1.6rem;
        }
    }

    a .s-badges__item {
        color: $color-black;
    }
}

.page-layout-1column .block.widget .product-items,
.column.main .product-items.slick-initialized {
    margin-left: 0;

    .product-item {
        margin: 0;
        padding-left: 0.45rem;
        padding-right: 0.45rem;

        @include media-breakpoint-up(lg) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
}
