.s-product-info-main {
    position: relative;

    .s-accordion--product-reviews {
        display: none;
    }

    .s-accordion {
        .s-accordion__button--green-text {
            color: $color-responsible-fashion;
            padding-left: 3rem;

            .s-icon--responsible {
                left: 0;
                top: 50%;
                width: 2.4rem;
                height: 2.4rem;
                position: absolute;
                transform: translateY(-50%);
            }
        }

        .s-icon-text {
            width: 50%;
            box-sizing: border-box;

            a {
                color: currentColor;
            }
        }

        .s-icon-text-wrapper--list {
            --icon-text-wrapper-list__icon__width: #{$icon-size-xxl};
            --icon-text-wrapper-list__icon__height: #{$icon-size-xxl};
            --icon-text-wrapper-list__icon__top: -0.6rem;
        }
    }

    .s-product-info-main__description,
    .s-product-info-main__short-description {
        padding: 1.2rem 0;

        p {
            margin: 0;
        }

        ul {
            padding-left: 0;

            li {
                list-style: none;
                padding-left: 2.7rem;
                display: block;
                position: relative;
                margin-bottom: 0.8rem;

                &::before {
                    content: '';
                    width: 0.3rem;
                    height: 0.3rem;
                    border-radius: 50%;
                    background-color: currentColor;
                    position: absolute;
                    top: 1rem;
                    left: 0;
                }
            }
        }
    }


    .swatch-attribute.size {
        display: none;
    }

    .s-swatch-size-dummy {
        float: none;
        margin: 2.4rem auto 0;
    }

    .swatch-attribute-options {
        margin-top: 0;

        @include media-breakpoint-up(md) {
            margin-top: 1.2rem;
        }
    }

    .swatch-attribute.color {
        justify-content: center;
        margin-top: 1.9rem;
        text-align: center;

        @include media-breakpoint-up(md) {
            margin-top: 4.8rem;
        }

        .swatch-attribute-options {
            justify-content: center;
        }

        .swatch-attribute-selected-option {
            padding-left: 0;
            color: $color-black;
        }

    }

    .product-options-bottom {
        margin-top: 2.4rem;

        button {
            width: auto;
            min-width: 34.5rem;
        }

        @include media-breakpoint-down(sm) {
            .actions {
                @include sr-only();
            }
        }
    }

    .s-wishlist-icon {
        position: absolute;
        right: 0;
        top: 2.4rem;

        @include media-breakpoint-up(md) {
            top: 4.8rem;
        }
    }

    .product-info-price {
        text-align: center;

        @include media-breakpoint-down(sm) {
            @include sr-only();
        }
    }

    .product-info-stock-sku {
        margin-top: 1rem;
        text-align: center;
        font-weight: $font-weight__light;

        @include media-breakpoint-up(md) {
            margin-top: 2.2rem;
        }
    }

    .swatch-opt {
        margin: 0;
    }
}

.s-product-detail--title {
    text-align: center;
    padding-top: 2.4rem;

    @include media-breakpoint-up(md) {
        padding-top: 4.8rem;
    }

    > .s-icon-text__icon {
        margin-bottom: 1.7rem;
        max-width: 5.8rem;
        max-height: 5.4rem;
        width: auto;

        @include media-breakpoint-up(md) {
            margin-bottom: 4.8rem;
            max-width: 8rem;
        }
    }

    > span > a {
        color: $text__color;
    }

    .page-title {
        margin-bottom: 0.6rem;
        font-weight: bold;
        word-break: break-word;

        @include media-breakpoint-up(md) {
            margin-bottom: 1rem;
        }
        @include media-breakpoint-down(sm) {
            line-height: 1.15;
        }
    }
}

.product-reviews-summary {
    display: none;
}


.price-box.price-final_price {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;

    .special-price {
        color: $color-sale;

        .price-label {
            // sass-lint:disable no-important
            display: none !important;
            // sass-lint:enable  no-important
        }
    }

    .old-price {
        margin-right: 2.4rem;

        .price-label {
            display: none;
        }
    }
}

.s-product-choose-size-button {
    margin-bottom: 2rem;
}

.additional-attributes-wrapper {
    margin-top: 2.4rem;

    @include media-breakpoint-up(md) {
        margin-top: 6.5rem;
    }
}

// TRUSTED SHOPS
#s-accordion-trustedShops {

    .s-accordion__button  {
        display: flex;
        align-items: center;

        #ts_product_widget {
            margin-left: 1rem;
        }
    }

    .ts-rating-light.productreviewsSummary {
        display: flex;
        align-items: center;
    }
}
