//
//  Variables
//  _____________________________________________

$autocomplete__background-color: $color-white !default;
$autocomplete__border: 1px solid $form-element-input__border-color !default;
$autocomplete-item__hover__color: fade-out($primary__color, 0.96) !default;
$autocomplete-item-amount__color: $text__color !default;

$search-input__background-color: $color-gray-darken1;
$search-input-placeholder__color: $color-gray-middle3;

.block-search {
    position: relative;
    z-index: 4;
    padding-left: 0;
    float: none;
    width: auto;
    margin-bottom: 0;

    .block {
        &-title {
            display: none;
        }
    }

    .block-content {
        margin-bottom: 0;
    }

    .label {
        display: inline-flex;
        float: right;
        @include s-icon-background-before('search', 'lg');

        @include media-breakpoint-up(lg) {
            @include s-icon-background-before('search');
        }

        &.active {
            + .control {
                input {
                    position: static;
                }
            }
        }
    }

    .action.search {
        display: inline-block;
        @include lib-button-reset();
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        padding: 0;
        @include s-icon-background-before('search', 'lg');

        @include media-breakpoint-up(lg) {
            @include s-icon-background-before('search');
            right: 1rem;
        }

        &:focus {
            &:before {
                @include lib-css(color, $color-gray20);
            }
        }

        &.disabled,
        &[disabled],
        fieldset[disabled] & {
            opacity: 1;
        }
    }

    .control {
        clear: both;
        border-top: 0;
        margin: 0;
        padding: 0;
    }

    .form.minisearch {

        @include media-breakpoint-down(md) {
            .actions {
                display: none;
            }

            &.active {
                input[type=text] {
                    width: 13.7rem;
                    background-color: $search-input__background-color;
                }

                .actions {
                    display: block;
                }

                label {
                    opacity: 0;
                }
            }
        }
        @include media-breakpoint-up(lg) {
            label {
                @include sr-only();
            }

            &.active {
                input[type=text] {
                    width: 15rem;
                }
            }
        }

        input[type=text] {
            @include lib-input-placeholder($search-input-placeholder__color);
            margin: 0;
            padding-right: 3.5rem;
            padding-top: 0;
            position: relative;
            background-color: $search-input__background-color;
            border: 0 none;
            left: auto;
            right: 0;
            border-radius: 2.5rem;
            height: 2.4rem;
            width: 13.7rem;
            font-size: $font-size__xs;
            font-weight: $font-weight__base;
            transition: width $header-transition-speed, background-color $header-transition-speed;

            @include media-breakpoint-up(lg) {
                height: 2rem;
            }

            @include media-breakpoint-down(md) {
                width: 0;
                position: absolute;
                top: 0;
                background-color: transparent;
            }
        }

    }

    .nested {
        display: none;
    }
}

.search-autocomplete {
    @extend .abs-add-box-sizing;
    display: none;
    margin-top: 0;
    overflow: hidden;
    position: absolute;
    z-index: 3;
    width: 25rem !important;
    box-shadow: $dropdown-list__shadow;
    padding: $dropdown-list__padding;
    background: $color-white;
    top: 100%;
    right: 0;
    transform: translateX(30%);

    @include media-breakpoint-up(sm) {
        transform: translateX(43%);
    }
    @include media-breakpoint-up(lg) {
        left: 0;
        right: unset;
        transform: unset;
    }

    dl {
        @include lib-list-reset-styles();
        @include lib-css(background, $autocomplete__background-color);

        dt {
            margin: 0;
            padding: $indent__xs 0;
            text-align: left;
            white-space: normal;
        }

        dd {
            @include lib-css(border-top, $autocomplete__border);
            cursor: pointer;
            margin: 0;
            padding: $indent__xs 0;
            position: relative;
            text-align: left;
            white-space: normal;
            display: flex;

            &:not(:empty) {
                border-top: 0;
            }

            &:first-child {
                border-top: none;
            }

            .product-item {
                flex: 1 1 auto;
                text-align: left;
                padding-left: 1rem;

                .price-box {
                    margin: 0;

                    .price {
                        font-size: $font-size__xs;
                        font-weight: $font-weight__base;

                        .price-box.price-final_price {
                            display: block;
                            flex-direction: inherit;
                            justify-content: inherit;
                            align-items: inherit;
                        }
                    }
                }
            }
        }
    }
}

.form.search.advanced {
    .fields.range {
        .field {
            &:first-child {
                position: relative;

                .control {
                    padding-right: 25px;

                    &:after {
                        content: ' \2013 ';
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        top: 6px;
                        width: 25px;
                    }
                }
            }

            &:last-child {
                position: relative;

                div.mage-error[generated] {
                    left: 0;
                    position: absolute;
                    top: 32px;
                }
            }

            &.with-addon {
                .control {
                    padding-right: 45px;
                }
            }
        }
    }

    .group.price {
        .addon {
            .addafter {
                background: none;
                border: 0;
                padding-top: 6px;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

.search.summary {
    margin-bottom: $indent__s;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .block-search {
        .block-content {
            margin-bottom: 0;
        }
    }
}


//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block-search {

        .nested {
            display: block;
            padding-top: 5px;
            position: absolute;
        }
    }
}
