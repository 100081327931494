.fotorama__arr__arr {
    .fotorama__arr & {
        @extend .fotorama-sprite;
        height: 4.8rem;
        width: 4.8rem;
        @include fotorama-abs-center();

        background: no-repeat 55% center;
        background-image: $icon__skip-right--black;
        background-size: 3rem;
        border-radius: 50%;
        border: 2px solid $primary__color;

        .ie9 & {
            margin: (-$size-fotorama-block / 2) 0 0 (-$size-fotorama-block / 2);
        }
    }

    .fotorama__arr--prev & {
        transform: translate3d(-50%, -50%, 0) rotate(180deg);
    }
}

.fotorama__arr {
    &:hover,
    &:focus-visible {
        .fotorama__arr__arr {
            background-image: $icon__skip-right--white;
            background-color: $primary__color;
        }
    }
}
