.s-link--icon {
    line-height: 3rem;
    padding-right: $icon-size-lg + 0.8rem;
    position: relative;
    font-weight: $font-weight__medium;

    .s-icon {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -($icon-size-lg / 2);
        width: $icon-size-lg;
        height: $icon-size-lg;
    }

    &:focus,
    &:active,
    &:hover {
        .s-icon {
            transform: translateX(4px);
        }
    }
}

.s-link--icon-inline {
    padding-right: 0;
    line-height: $line-height__base;

    .s-icon {
        position: relative;
        top: 0;
        margin-top: -2px;
        width: $icon-size-lg;
        height: $icon-size-lg;
        vertical-align: middle;
    }
}

.s-link--icon-down {

    &:focus,
    &:active,
    &:hover {
        .s-icon {
            transform: translateY(4px);
        }
    }
}

.s-link--icon-close {
    color: $color-black;
    margin-right: 2rem;

    .s-icon {
        right: 0.5rem;
    }

    &:focus,
    &:active,
    &:hover {
        .s-icon {
            transform: none;
        }
    }
}
