.s-cms-content-section--content-page-headline + .s-cms-content-section--content-element {
    margin-top: 0;

    @include media-breakpoint-up(md) {
        margin-top: 1.3rem;
    }
}

.s-cms-content--content-element {

    p {
        margin-bottom: 2.4rem;
    }

    .s-cms-content__text {

        p {
            text-align: left;
        }
    }

    .s-cms-content__image-pos--right {

        .s-cms-content__image {
            margin-top: 2.4rem;

            @include media-breakpoint-up(md) {
                margin-top: 0;
            }
        }
    }
}
