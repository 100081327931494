.s-background-style--golf {
    background-color: $color-golf;
    color: $text__color__reversed;
}
.s-background-style--m5 {
    background-color: $color-m5;
    color: $text__color__reversed;
}
.s-background-style--sustainability {
    background-color: $color-360rf;
    color: $text__color__reversed;
}
.s-background-style--sale {
    background-color: $color-sale;
    color: $text__color__reversed;
}
.s-background-style--spring {
    // TODO: add colors
    background-color: $color-summer;
}
.s-background-style--summer {
    background-color: $color-summer;
}
.s-background-style--autumn {
    // TODO: add colors
    background-color: $color-winter;
}
.s-background-style--winter {
    background-color: $color-winter;
}

.s-background-style--golf,
.s-background-style--m5,
.s-background-style--sustainability,
.s-background-style--sale {
    color: $text__color__reversed;

    a {
        color: $text__color__reversed;
    }
}
