$header-flyout-icon-space: 3.8rem;
$header-flyout-list-item__padding-bottom: 2.1rem;

.header-flyout {
    width: calc(100% - 2rem);

    .s-icon {
        width: $icon-size-lg;
        height: $icon-size-lg;
    }

    a {
        color: $text__color;
    }

    .main_nav ul {
        margin: 0;
        padding: 0;
    }

    .no-link-to-first-child {
        padding-bottom: $header-flyout-list-item__padding-bottom;

        > span {
            font-weight: $font-weight__semibold;
            @include s-icon-background-after('skip-down--green', 'sm', false);
            position: relative;
            display: block;
            padding-right: $icon-size-sm;
            cursor: pointer;

            &.s-with-icon {
                display: flex;
                align-items: center;
            }

            &::after {
                transition: transform $header-transition-speed;
                position: absolute;
                right: 0;
                top: 50%;
                margin-top: calc((#{$icon-size-sm} / 2) * -1);
            }

            &.active {
                &::after {
                    transform: rotate(180deg);
                }
            }
        }

        > ul {
            padding-left: 4.4rem;
            margin: 0;
            list-style: none;

            > li{
                padding-top: 0.7rem;
                line-height: 1.2;

                &:first-child {
                    padding-top: $header-flyout-list-item__padding-bottom;
                }
            }
            > li:last-child {
                padding-bottom: $header-flyout-list-item__padding-bottom;
            }
        }

        &:last-of-type > ul > li:last-child {
            padding-bottom: 0;
        }
    }


    .flyout-top-links {
        padding-left: 0;
        margin: 0;
        list-style: none;

        > li {
            padding-bottom: 2.5rem;

            > a.action.showcart,
            > span.s-with-icon,
            > a {
                display: flex;
                align-items: center;

                > :first-child {
                    width: $header-flyout-icon-space;
                }

                .s-icon-container,
                .s-icon-counter {
                    height: $icon-size-lg;
                }

                .s-icon-counter {
                    margin-right: 0;
                }

                &:not(.s-with-icon) {
                    @include hover-focus-active() {
                        text-decoration: none;

                        span.s-text {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .switcher-dropdown {
            padding: 0;
            margin: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            max-width: 24rem;

            li {
                padding-right: 1rem;

                @include media-breakpoint-up(md) {
                    padding-right: 1.2rem;
                }

                &:last-child {
                    padding-right: 0;
                }

                a {
                    &:hover,
                    &:focus,
                    &:active,
                    &.active {
                        position: relative;
                        text-decoration: none;

                        &::after {
                            content: '';
                            height: 1px;
                            width: 100%;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            background: $color-additional-green;
                        }
                    }
                }
            }
        }
    }

    .flyout-minicart {

        .minicart-wrapper {
            margin-left: 0;
        }

        .action.showcart {
            &::before {
                display: none;
            }
        }
    }

    .flyout-top-links,
    .s-header-flyout__retailer-link,
    .s-header-flyout-service {
        padding-top: 3.6rem;

        @include media-breakpoint-up(md) {
            padding-top: 5rem;
        }
    }

    .s-icon-counter {
        justify-content: flex-start;
    }

}

.s-header-flyout-service {

    > div {
        position: relative;
        padding-bottom: 2.5rem;

        @include media-breakpoint-up(md) {
            padding-bottom: 3.2rem;
        }
    }

    .s-icon {
        position: absolute;
        top: 0;
        left: 0;

        @include media-breakpoint-up(xl) {
            top: 0.3rem;
        }
    }

    .content {
        padding-left: $header-flyout-icon-space;

        p {
            margin: 0;

            & + p {
                margin-top: 0.6rem;
            }
        }
    }

    .s-text-xs {
        font-size: $font-size__xs;
    }
}
