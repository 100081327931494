.s-tooltip {

    &--right {
        @include lib-tooltip(right);
    }

    &--left {
        @include lib-tooltip(left);
    }

    &--bottom {
        @include lib-tooltip(bottom);
    }

    &--top {
        @include lib-tooltip(top);
    }

    .s-tooltip__content {
        text-align: center;
        // sass-lint:disable no-important
        left: 50% !important;
        // sass-lint:enable no-important
        transform: translateX(-50%);
        box-shadow: $tooltip__box-shadow;

        &.s-tooltip__content--filter {
            transform: translate(-50%, -20px);
        }

        &::before {
            display: none;
        }

        &::after {
            left: 50%;
            transform: translateX(calc(-50% - 1px));
        }

        &--color {
            max-width: 16.2rem;
            box-sizing: border-box;
        }
    }

    &--small {
        .s-tooltip__content {
            padding: $tooltip__padding__small;
        }
    }
}

.s-swatches-tooltip-wrapper {
    display: none;
    min-height: 100px;
    position: absolute;
    z-index: 99;

    .corner {
        position: absolute;
        background: red;
        width: 20px;
        height: 20px;
    }
}
