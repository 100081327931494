.field[name="shippingAddress.telephone"] {
    display: block;
}

select {
    background-image: $icon__skip-down;
    background-size: $icon-size-sm;
    background-position: 99% center;
    background-repeat: no-repeat;

    &:active {
        background-image: $icon__skip-up;
    }

    option {
        background-color: $page__background-color;
        padding: 1rem;
    }
}


.fieldset > .field:not(.choice) > .label {
    padding-top: 0;
}

.fieldset > .field {
    margin-bottom: 1.6rem;
}

.fieldset > .field:not(.choice) > .label {
    text-align: left;
}


.fieldset > .field:not(.choice) > .label {
    width: unset;
}

.field .control._with-tooltip input {
    margin-right: 0;
    width: 100%;
}

.field-tooltip.toggle .label {
    display: none;
}

.fieldset > .field:not(.choice) > .control {
    width: 100%;
}

.fieldset > .field .additional {
    margin-top: 0;
}

input {
    position: relative;
}

input[type='radio'] {
    position: relative;

    &:focus {
        box-shadow: none;

        &::after {
            border: 0.2rem solid $forms__active-color;
        }
    }
}

input[type='text']:focus-visible {
    outline: 1px solid $color-green;
}

input[type='radio'] + .label {
    padding-left: 3.6rem;
}

input[type='radio']:after {
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 1.5rem;
    top: 0;
    left: -0.1rem;
    position: absolute;
    background-color: $page__background-color;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 0.1rem solid $font__color;

    @include media-breakpoint-up(md) {
        top: -0.1rem;
    }
}

input[type='radio']:checked {
    &:focus {
        &::after {
            border: 0.2rem solid $forms__active-color;
        }
    }
}

input[type='radio']:checked::after {
    width: 1.2rem;
    height: 1.2rem;
    background-color: $page__background-color;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 0.2rem solid $font__color;
}

input[type='radio']:checked::before {
    background-color: $font__color;
    content: '';
    width: 0.8rem;
    display: inline-block;
    visibility: visible;
    height: 0.8rem;
    position: absolute;
    border-radius: 1.5rem;
    z-index: 1;
    top: 0.1rem;
    left: 0;
    border: 0.3rem solid $page__background-color;

    @include media-breakpoint-up(md) {
        top: 0;
    }
}

label[for="newsletter-subscribe"] {
    padding-left: 1.5rem;
    margin-top: -0.5rem;
}

.field[name="shippingAddress.postcode"],
.field[name="shippingAddress.city"],
.field[name="shippingAddress.street.1"],
.field[name="shippingAddress.street.0"] {
    width: 100%;
}

.field[name="shippingAddress.street.0"] {
    margin-bottom: 1.6rem;
}

// DESKTOP
@include media-breakpoint-up(md) {
    .field[name="shippingAddress.lastname"],
    .field[name="shippingAddress.firstname"],
    .field[name="shippingAddress.prefix"] {
        width: calc(50% - 1.5rem);
    }

    .fieldset > .field[name="shippingAddress.street.0"].additional {
        margin-top: 0;
    }

    .field[name="shippingAddress.city"],
    .field[name="shippingAddress.street.0"] {
        width: calc(75% - 1.5rem);
    }

    .field[name="shippingAddress.street.0"],
    .field[name="shippingAddress.street.1"] {
        margin-bottom: 0;
    }

    .field[name="shippingAddress.postcode"],
    .field[name="shippingAddress.street.1"] {
        width: calc(25% - 1.5rem);
    }


    .field[name="shippingAddress.postcode"],
    .field[name="shippingAddress.street.0"],
    .field[name="shippingAddress.firstname"] {
        float: left;
    }

    .field[name="shippingAddress.city"],
    .field[name="shippingAddress.street.1"],
    .field[name="shippingAddress.lastname"] {
        float: right;
    }

    .fieldset > .field.choice:before,
    .fieldset > .field.no-label:before {
        display: none;
    }
}

._error > .label {
    color: $error__color;

    &::after {
        // sass-lint:disable no-important
        color: $error__color !important;
        // sass-lint:enable no-important
    }
}

fieldset > .control > .field {
    @include _lib-form-field-required(
        $form-field-label-asterisk__color,
        $form-field-label-asterisk__font-size,
        $form-field-label-asterisk__font-family,
        $form-field-label-asterisk__font-weight,
        $form-field-label-asterisk__font-style,
        $form-field-label-asterisk__line-height,
        $form-field-label-asterisk__margin
    );
}

.fieldset.address[data-form="billing-new-address"] {

    .control .field:nth-child(2) {
        margin-top: 1.6rem;

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }
}

._has-datepicker {
    font-weight: $font-weight__medium;
}
