#modal-locationfinder {
    .search-inputs {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;

        div {
            margin-bottom: 1.6rem;
            width: 49%;
        }
    }

    #modal-locationfinder-search {
        margin-bottom: 3.2rem;
    }

    .map-popup-container {
        h4 {
            margin-bottom: 0;
        }

        .action-primary {
            margin-top: 1.6rem;
        }
    }

    .leaflet-popup-close-button {
        top: 1rem;
        right: 1rem;
    }

}
.nrshipping-settings.dhlpaket {
    padding-left: 2rem;

    @include media-breakpoint-up(sm) {
        padding-left: 5rem;
    }
}

.nrshipping-settings-container {
    .locationfinder-option {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        .action-remove-button {
            margin-bottom: 1.6rem;
            margin-right: 3rem;
        }
    }

    .admin__control-text {
        margin-bottom: 1.6rem;
    }

}

.nrshipping-option-selections-content {
    margin-top: 2rem;

    h4 {
        display: none;
    }

    ul {
        list-style: none;
        padding: 0;

        strong {
            font-weight: $font-weight__base;
        }

        span {
            display: block;
        }
    }
}


.nrshipping-settings.dhlpaket {
    img {
        display: none;
    }

    h3,
    h4 {
        font-size: $font-size__base;
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: 1rem;
    }
}
