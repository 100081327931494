body.catalog-category-view {

    @include media-breakpoint-down(sm) {
        .page-main {
            display: flex;
            flex-direction: column;

            .s-category-top-wrapper {
                order: 1;
                margin-bottom: 0;
                padding-top: 2rem;

                .s-link--icon {
                    display: none;
                }
            }
            .s-category-description-bottom {
                order: 2;
            }
        }
    }
}

.s-category-title {
    .page-title {
        text-align: center;
        display: block;
        margin-bottom: 3.5rem;
    }
}

.s-category-top-wrapper {
    margin-bottom: 6rem;
}

.s-category-view {

    @include media-breakpoint-up(md) {
        display: flex;
        align-items: stretch;
    }
}

.s-category-image {
    padding: 3.2rem;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
        flex: 1 1 50%;
        display: flex;
        align-items: center;

        &+ .s-category-description {
            flex: 1 1 50%;
        }
    }
}


.s-category-description {
    padding: 0;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
        flex: 1 1 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 2.5rem;
        padding-bottom: 3.5rem;
        padding-left: 2.4rem;
        padding-right: 2.4rem;
        text-align: center;
    }

    p {
        margin-bottom: 2.4rem;

        @include media-breakpoint-up(md) {
            max-width: 60rem;
            margin: 0 auto;
        }
    }
}
