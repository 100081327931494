//
//  Typography
//  ---------------------------------------------

//  Headings
$h1__margin-bottom__desktop                                   : $indent__xl !default;

//
//  Layout grid
//  ---------------------------------------------

//  Header
$header-icons-color                                           : $text__color !default;
$header-icons-color-hover                                     : $text__color !default;
$header-sticky-height                                         : 11.2rem !default;
$header-sticky-height-md                                      : 8.6rem !default;
$header-transition-speed                                      : 0.5s !default;

//
//  Icons
//  ---------------------------------------------
//

$icon-edit                                                    : '\e606' !default;
$icon-size-sm                                                 : 1.5rem;
$icon-size-md                                                 : 2rem;
$icon-size-lg                                                 : 2.4rem;
$icon-size-xl                                                 : 3.2rem;
$icon-size-xxl                                                : 4rem;
$icon-size-button                                             : 2.8rem;
$icon-size-usp                                                : 2.2rem;
$icon-size-image-sm                                           : 2.8rem;
$icon-size-image-lg                                           : 6.4rem;
$icon-size-image-xl                                           : 8rem;

$icon-size: () !default;
$icon-size: map-merge(
    (
        "sm": $icon-size-sm,
        "md": $icon-size-md,
        "lg": $icon-size-lg,
        "xl": $icon-size-xl,
        "xxl": $icon-size-xxl,
        "button": $icon-size-button,
        "image-sm": $icon-size-image-sm,
        "image-lg": $icon-size-image-lg,
        "image-xl": $icon-size-image-xl
    ),
        $icon-size
);

$icon__login                                                  : url("../icons/myh-icon_login.svg");
$icon__navigation                                             : url("../icons/myh-icon_navigation.svg");
$icon__search                                                 : url("../icons/myh-icon_suche.svg");
$icon__nachhaltigkeit                                         : url("../icons/myh-icon_nachhaltigkeit.svg");
$icon__skip-up                                                : url("../icons/myh-icon_skip-up.svg");
$icon__skip-up--green                                         : url("../icons/myh-icon_skip-up--green.svg");
$icon__skip-down                                              : url("../icons/myh-icon_skip-down.svg");
$icon__web                                                    : url("../icons/myh-icon_web.svg");
$icon__web--green                                             : url("../icons/myh-icon_web--green.svg");
$icon__route                                                  : url("../icons/myh-icon_route.svg");
$icon__route--green                                           : url("../icons/myh-icon_route--green.svg");
$icon__tel                                                    : url("../icons/myh-icon_phone.svg");
$icon__tel--green                                             : url("../icons/myh-icon_phone--green.svg");
$icon__close                                                  : url("../icons/myh-icon_close.svg");
$icon__skip-down--green                                       : url("../images/myh-icon_skip-down--green.svg");
$icon__skip-down--white                                       : url("../images/myh-icon_skip-down--white.svg");
$icon__skip-down--black                                       : url("../images/myh-icon_skip-down--black.svg");
$icon__skip-right--white                                      : url("../images/arrow-right-white.svg");
$icon__skip-right--black                                      : url("../images/arrow-right-black.svg");
$icon__nachhaltigkeit--weiss                                  : url("../images/myh-icon_nachhaltigkeit.svg");
$icon__check                                                  : url("../images/myh-icon_check--green.svg");
$icon__cart                                                   : url("../images/myh-icon_cart.svg");
$icon__arrow                                                  : url("../images/myh-icon_link.svg");
$icon__arrow__white                                           : url("../images/myh-icon_link__white.svg");
$icon__warning__white                                         : url("../images/myh-icon_warnung--white.svg");
$icon__warning__black                                         : url("../images/myh-icon_warnung.svg");

$icon-image: () !default;
$icon-image: map-merge(
    (
        "login": $icon__login,
        "navigation": $icon__navigation,
        "search": $icon__search,
        "skip-up": $icon__skip-up,
        "skip-up--green": $icon__skip-up--green,
        "skip-down": $icon__skip-down,
        "skip-down--green": $icon__skip-down--green,
        "skip-down--white": $icon__skip-down--white,
        "skip-down--black": $icon__skip-down--black,
        "nachhaltigkeit": $icon__nachhaltigkeit,
        "nachhaltigkeit-weiss": $icon__nachhaltigkeit--weiss,
        "check": $icon__check,
        "cart": $icon__cart,
        "arrow": $icon__arrow,
        "arrow__white": $icon__arrow__white
    ),
        $icon-image
);

//
//  Popups
//  ---------------------------------------------

//  Checkout tooltip
$checkout-tooltip-icon-arrow__font-size                       : 10px !default;
$checkout-tooltip-icon__font-size                             : 21px !default;

$checkout-tooltip-content-mobile__right                       : -($indent__s) !default;
$checkout-tooltip-content-mobile__top                         : 30px + $checkout-tooltip-icon-arrow__font-size !default;
$checkout-tooltip-content__background-color                   : $color-gray-light01 !default;
$checkout-tooltip-content__padding                            : 12px !default;
$checkout-tooltip-content__border-width                       : 1px !default;

$checkout-tooltip-content__border-color                       : $color-gray60 !default;
$checkout-tooltip-content__font-size                          : $font-size__responsive__base !default;
$checkout-tooltip-content__width                              : 270px !default;
$checkout-tooltip-content__active__border-color               : darken($checkout-tooltip-content__border-color, 20%) !default;
$checkout-tooltip-icon-arrow__left                            : -( $checkout-tooltip-content__padding + $checkout-tooltip-icon-arrow__font-size - $checkout-tooltip-content__border-width) !default;

$checkout-billing-address-details__line-height                : 27px !default;

// Checkout
$checkout-step-title__padding                                 : $indent__s !default;
$checkout-step-title__border                                  : $border-width__base solid $color-gray80 !default;
$checkout-step-title__font-size                               : 26px !default;
$checkout-step-title__font-weight                             : $font-weight__light !default;
$checkout-sidebar__columns                                    : 4 !default;
$checkout-shipping-address__max-width                         : 500px !default;

//
//  Sidebar
//  ---------------------------------------------

// Used in cart sidebar, Checkout sidebar, Tier Prices, My account navigation, Rating block background
$sidebar__background-color                                    : $page__background-color !default;
$sidebar__box-shadow                                          : 0 0 10px 0 rgba(0, 0, 0, 0.1) !default;
$sidebar__padding                                             : 3.2rem 1.6rem;
$sidebar__padding--desktop                                    : 6rem 4rem;

//
//  Variables missing in extend.scss
//  ---------------------------------------------

$addto-color                                                  : $primary__color !default;
$addto-hover-color                                            : $link__hover__color !default;

//
//  Product
//  ---------------------------------------------

$product-name-link__color                                     : $text__color !default;
$product-name-link__color__active                             : $text__color !default;
$product-name-link__color__hover                              : $text__color !default;
$product-name-link__color__visited                            : $text__color !default;

$product-name-link__text-decoration                           : none !default;
$product-name-link__text-decoration__active                   : $link__hover__text-decoration !default;
$product-name-link__text-decoration__hover                    : $link__hover__text-decoration !default;
$product-name-link__text-decoration__visited                  : $link__hover__text-decoration !default;

//
//  Button
//  ---------------------------------------------

$button__shadow                                               : inset 0 2px 1px rgba(0, 0, 0, 0.12) !default;

//
//  Minicart
//  ---------------------------------------------

$minicart-icons-color                                         : $header-icons-color !default;
$minicart-icons-color-hover                                   : $header-icons-color-hover !default;

//
// Div
// ----------------------------------------------
$box-shadow                                                     : 0 0 1rem -0.1rem rgba(0, 0, 0, 0.14);

//
// Transitions
// ----------------------------------------------
$transition__transform-duration                             : 0.4s;
$transition__transform                                      : transform $transition__transform-duration;

//
// Transitions
// ----------------------------------------------
$carousel-transition-duration                                  : 1s;

//
//  Sticky Cart
//  ---------------------------------------------

$sticky-cart-height-xs                                         : 13rem !default;
$sticky-cart-height-md                                         : 11rem !default;
$sticky-cart-height-xl                                         : 7.5rem !default;

//
//  Back to Top Button
//  ---------------------------------------------

$back-to-top-button-size                                       : 4.4rem !default;
