.s-order-details-items,
.s-block-order-details-view,
.s-form-edit-account,
.s-block-dashboard-info,
.s-form-address-edit,
.s-block-addresses-default,
.s-block-dashboard-addresses {
    margin-bottom: 2rem;

    .s-block-title {
        margin-bottom: 2.4rem;

        .s-h3 {
            margin-top: 0;
            display: inline;
        }

        .s-h4 {
            margin: 0;
        }

        .action.edit {
            font-weight: $font-weight__medium;
        }
    }

    .block-content .s-h5 {
        margin: 0;
    }

    .box-actions {
        margin-top: 1rem;
        font-weight: $font-weight__medium;

        @include media-breakpoint-up(md) {
            margin-top: 1.6rem;
        }
    }

    .input-text,
    select {
        font-weight: $font-weight__medium;
    }
}

// Account
.s-form-edit-account {
    .legend {
        width: 100%;
    }

    .customer-name-prefix {
        margin-bottom: 0;
    }

    .fieldset.password {
        @include media-breakpoint-up(md) {
            .field.email,
            .field.password {
                width: calc(50% - 1rem);
            }
        }
    }

    .field-dob {
        margin-bottom: 3.7rem;
        margin-top: 1.6rem;

        @include media-breakpoint-up(md) {
            margin-top: 0;
            width: calc(50% - 1rem);
        }

    }

    .fullname.customer-name-prefix {
        margin-bottom: 2.6rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }

    .field.choice {
        margin-bottom: 0;
    }

    .fieldset.info {
        margin-bottom: 1.6rem;
    }

    .fieldset.additional_info {
        display: none;
    }
}

// Wishlist
.s-form-wishlist-items {
    .box-tocart {
        .field.qty {
            display: flex;
            align-items: center;
            margin-top: 2.2rem;
            margin-bottom: 2.2rem;
        }
    }
}

// Newsletter
.s-form-newsletter-manage {
    label[for="subscription"] {
        padding-left: 1.6rem;
        font-weight: $font-weight__base;
    }
}

// Address
.s-form-address-edit {

    .legend {
        width: 100%;
    }

    .fieldset {
        margin-bottom: 1.6rem;
    }

    .s-h3 {
        margin: 0;
    }

    .customer-name-prefix {
        margin-bottom: 0;
    }

    .actions-toolbar {
        margin-top: 3rem;
    }
}

.table-order-items {
    table-layout: fixed;

    th,
    td {
        width: auto;
    }

    th.col.qty,
    td.col.qty {
        width: 7rem;
    }

    th.col.price,
    th.col.discount,
    th.col.total,
    th.col.subtotal,
    td.col.price,
    td.col.discount,
    td.col.total,
    td.col.subtotal {
        width: 10rem;
    }
}

// Orders / Credit Memo
.s-order-details-items,
.table-order-items.invoice,
.order-items-creditmemo,
.table-order-items.invoice,
.s-table-creditmemo-items,
.s-orders-history {
    .table {
        table-layout: fixed;
    }

    .cart.table-wrapper .col.price,
    .cart.table-wrapper .col.qty,
    .order-items.table-wrapper .col.price,
    .order-items.table-wrapper .col.qty {
        text-align: center;
    }

    td.col.actions {
        font-weight: $font-weight__medium;

        .view {
            margin-right: 1.6rem;

            @include media-breakpoint-up(sm) {
                margin-right: 0;
            }
        }

    }

    .table:not(.cart):not(.totals) tfoot .mark {
        font-weight: $font-weight__base;
        @include lib-font-size($font-size__base);
    }

    .col.id {
        max-width: 20rem;
    }

    .col.qty,
    .col.price {
        text-align: center;
    }

    td.col.id {
        font-weight: $font-weight__medium;
    }

    .account .table-wrapper .action {
        margin-right: 0;
    }

    tr:first-of-type td.col {
        padding-top: 3rem;
    }

    td.col {
        padding-bottom: 3rem;
    }
}

.order-details-items {
    border-bottom: none;

    .table {
        table-layout: auto;
    }
}

// Order Detail / Credit Memo Detail / Invoice Detail
.account.sales-order-invoice,
.account.sales-order-creditmemo,
.account.sales-order-view {
    .columns {
        margin-top: 6rem;
    }

    .page-title {
        width: 100%;
        margin-bottom: 1rem;
    }

    .order-status,
    .order-date {
        margin-bottom: 0;
    }

    .order-date {
        display: inline-block;
        margin-left: 1.6rem;
    }

    .order-actions-toolbar {
        margin-top: 1rem;
        margin-bottom: 0;

        .actions {
            display: flex;
            justify-content: center;
        }
    }
}

.items.order-links {
    border-bottom: none;

    .nav.item {
        display: inline;
        padding-bottom: 0.4rem;
        border-bottom: 1px solid transparent;
        font-weight: $font-weight__medium;

        &:hover {
            border-color: $color-responsible-fashion;
        }

        a {
            color: $font__color;

            &:hover {
                color: $color-responsible-fashion;
                text-decoration: none;
            }
        }

        &.current {
            color: $color-responsible-fashion;
            border-color: $color-responsible-fashion;
        }
    }
}

.s-order-details-items {
    margin-top: 3rem;
    border-bottom: none;

    .price-including-tax .price,
    .price-excluding-tax .price {
        font-weight: $font-weight__base;
    }
}

.s-block-order-details-view {
    .payment-method:first-of-type {
        margin-top: 0;
    }
}

// Wishlist
.s-form-wishlist-items {
    .wishlist-index-index .product-item-info {
        width: 100%;
    }

    .tooltip.toggle,
    .product-item-tooltip {
        width: 100%;
        clear: both;
    }

    .product-items {
        justify-content: space-between;
    }

    .product-item {
        padding-left: 0;
    }

    .actions-toolbar {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    .product-item-name {
        float: left;
        max-width: 14rem;
        line-height: 1.2;

        .product-item-link {
            color: $font__color;
            @include lib-font-size();
            font-weight: $font-weight__medium;
        }
    }

    .product-item-inner {
        clear: both;
    }

    .product-item-photo {
        @include media-breakpoint-down(sm) {
            float: unset;
            margin-right: 0;
        }
    }

    .product-image-container {
        width: 100%;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }

    .price-box.price-final_price {
        display: block;
        float: right;
        padding: 0;
        margin: 0;

        &::after {
            clear: both;
            content: '';
        }

        .price-as-configured {
            margin-bottom: 1.6rem;
        }

    }

    .product-item .price-box .price {
        @include lib-font-size();
        font-weight: $font-weight__medium;
    }

    .s-button-link.share,
    .s-button-link.update {
        color: $font__color;
        margin-right: 4rem;
        margin-left: 4rem;

        @include media-breakpoint-up(sm) {
            margin-left: 0;
        }

        .s-icon {
            fill: $font__color;
        }
    }

    .product-item-actions {
        font-weight: $font-weight__medium;
    }

    .products-grid .product-item {
        width: calc(100% / 2 - 1.5rem);
        margin-left: 0;
    }

    @include media-breakpoint-up(lg) {
        .products-grid .product-item {
            width: calc(100% / 3 - 1.5rem);
        }
    }
}


.wishlist-index-index .products-grid,
.wishlist-index-index .product-item-info {
    width: 100%;

    .product-item {
        margin-bottom: 3rem;
    }
}
