.opc-wrapper {
    width: calc(100% / 12 * 7);
    padding-right: 0;

    @include max-screen($screen__l) {
        width: 100%;
    }

    .fieldset > .field .note {

        &:before {
            display: none;
        }
    }

    .form-shipping-address,
    .form-login {
        max-width: 100%;
        border-bottom: none;
    }

    .s-newsletter-subscribe {
        display: flex;
        align-items: flex-start;
    }
}

.abs-checkout-title,
.opc-wrapper .step-title,
.opc-block-shipping-information .shipping-information-title,
.opc-block-summary > .title,
.checkout-agreements-items .checkout-agreements-item-title {
    border-bottom: none;
}

.checkout-agreement {
    .action.action-show {
        text-align: left;
    }
}

.shipping-address-items {
    margin-top: 3rem;
}

.opc-wrapper .shipping-address-item {
    &.selected-item {
        border-color: $color-responsible-fashion;

        &::after {
            content: '';
            background: url("../images/myh-icon_check--green.svg") no-repeat transparent;
        }
    }

    @include media-breakpoint-up(md) {
        width: 45%;
    }
}


.opc-block-shipping-information .shipping-information-title .action-edit {
    &:hover {
        &:before {
            color: $link__color;
        }
    }
}

.table-checkout-shipping-method tbody td:first-child {

    &:not(.col-error) {
        max-width: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

.table-checkout-shipping-method {
    min-width: 100%;

    td {
        vertical-align: middle;
        text-align: center;
    }

    > * {
        border: none transparent;
    }

    tbody {
        .col-price,
        .col-carrier {
            display: flex;
            align-items: center;
        }
    }

    .col-price {
        font-weight: $font-weight__semibold;
        max-width: 10rem;
    }

    .col-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 13rem;

        @include media-breakpoint-down(sm) {
            padding: 0;
        }
    }

    .col-logo img {
        width: 9.8rem;
    }

    .col-carrier {
        text-align: left;
    }
}

.table-checkout-shipping-method {
    margin-top: 2rem;
}

.table-checkout-shipping-method tbody td {
    border-top: none;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.onepage-checkout-shipping-method-additional-load {
    padding-left: 4.5rem;
    margin-bottom: 2rem;
}

.checkout-index-index .modal-popup .form-shipping-address {
    max-width: unset;
}

.checkout-index-index .modal-popup .modal-footer .action-save-address {
    margin: 0;
}
