.s-checkout-summary-totals {
    margin-bottom: 4rem;

    .grand.totals {
        border-top: 0.2rem solid $color-grayD7;
        border-bottom: 0.2rem solid $color-black;

        .abs-sidebar-totals .mark,
        .cart-totals .mark,
        .opc-block-summary .table-totals .mark {
            font-weight: $font-weight__semibold;
        }

        th,
        td {
            padding-top: 1.5rem;
            padding-bottom: 1.2rem;
            padding-left: 0;
        }
    }

    .totals.sub,
    .totals.excl {
        td,
        th {
            padding: 0.4rem 0;
        }
    }

    .totals.gift-card,
    .totals.sub,
    .totals.shipping,
    .totals-tax,
    .grand.totals {
        th,
        th:last-of-type {
            text-align: left;
        }
    }

}

