.s-cms-content--category-teaser {

    .s-cms-content__items > div {

        a {
            color: $text__color;
            display: block;
            position: relative;

            img {
                display: block;
            }
        }
    }
}

.s-cat-teaser-item {
    position: relative;

    &.s-cat-teaser-item--default {
        .s-cat-teaser-item__link-text {
            margin-top: 1.2rem;
            display: block;
        }
    }

    .s-cms-content__action-wrapper {
        position: absolute;
        bottom: 62%;
        left: 2rem;
        right: 2rem;
        display: flex;
        justify-content: center;

        &._position--centered {
            top: 50%;
            bottom: auto;
            transform: translateY(-50%);
        }
    }

    .s-cms-content__action {
        display: inline-flex;

        .action {
            min-width: 0;
            padding-left: 2.4rem;
            padding-right: 2.4rem;
        }
    }
}
