.swatch-option.color {
    --swatch-option__size: 2.4rem;

    @include media-breakpoint-up(md) {
        --swatch-option__size: 3.2rem;
    }

    &.swatch-with-border {
        --swatch-option__size: 2.2rem;

        @include media-breakpoint-up(md) {
            --swatch-option__size: 3rem;
        }
    }

    &.swatch-option--small {
        --swatch-option__size: 1.6rem;
    }
}


.swatch-option.color.selected {
    pointer-events: none;
}

// COLOR SWACHES
.swatch-option.color {
    width: var(--swatch-option__size);
    height: var(--swatch-option__size);
    min-width: auto;
    border-radius: 50%;
    padding: 0;
    float: unset;
    border: 0 none;
    margin: 1rem;


    @include media-breakpoint-up(md) {
        margin: 0.7rem;
    }

    &.swatch-with-border {
        border-color: $border-color;
    }

    &::after {
        content: '';
        width: 1.8rem;
        height: 1.8rem;
        background-color: inherit;
        outline: none;
        border: 0.1rem solid transparent;
        min-width: 1.8rem;
        max-width: 1.8rem;
        min-height: 1.8rem;
        max-height: 1.8rem;
        position: absolute;
        border-radius: 2.5rem;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &.selected {
        outline: none;
    }

    &.selected::after {
        border-color: $color-white;
    }

    &.swatch-option--small {
        margin: 0 1.4rem 1rem 0;

        &::after {
            border-width: 1px;
            width: 0.6rem;
            height: 0.6rem;
            min-width: 0.6rem;
            max-width: 0.6rem;
            min-height: 0.6rem;
            max-height: 0.6rem;
        }
    }
}

.swatch-option.color:not(.disabled):hover {
    outline: none;
    border: 0 none;

    &::after {
        border-color: $color-white;
    }

    &.swatch-with-border {
        border-color: $border-color;

        &::after {
            border-color: $border-color;
        }
    }
}

.swatch-attribute.size .swatch-attribute-options {

    tbody > tr:first-of-type {
        td {
            padding-top: 1.3rem;

            &.s-size-selector__group-title {
                padding-top: 1.5rem;
            }
        }
    }

    tbody > tr {
        td {
            padding-top: 0.2rem;
        }
    }

    th {
        border-bottom: 1px solid lightgrey;
        font-weight: 100;
        padding-left: 0;
    }

    th,
    td {
        box-sizing: border-box;
    }

}

.swatch-attribute.size .swatch-attribute-options .header-fixed__table th {
    border-bottom: none;
}

.swatch-attribute.color {
    .swatch-attribute-options {
        display: flex;
        flex-wrap: wrap;
    }

    &:active {
        box-shadow: none;
    }
}

.swatch-attribute.color .swatch-attribute-label {
    display: none;
}


.swatch-option.text.selected {
    background-color: $page__background-color;
}

.swatch-option.text:not(.disabled):hover {
    border: 0.1rem solid transparent;
    outline: none;
    background-color: $size-icon__active;
}


.swatch-input.super-attribute-select {
    height: 0;
    position: absolute;
}
