.s-page-shop-features {
    @include clearfix();
    display: none;
    background-color: $color-360rf;
    color: $text__color__reversed;
    font-size: $font-size__xs;
    font-weight: $font-weight__semibold;
    line-height: $icon-size-usp;
    position: relative;
    z-index: 1;
}

.s-page-shop-features__ticker-wrapper {
    position: relative;
    margin: 0 auto;
    padding: 0.7rem ($indent__base + $indent__base) 0.7rem;

    @include media-breakpoint-up(md) {
        padding: 0.8rem ($indent__base + $indent__base) 0.8rem;
    }
}

.s-page-shop-features__close {
    position: absolute;
    right: 0;
    top: 50%;
    z-index: $zindex-dropdown;
    color: $text__color__reversed;
    height: $icon-size-usp;
    transform: translate(0, -50%);
}

.s-page-shop-features__ticker {
    ul {
        margin: 0;
        padding: 0;
        text-align: center;
    }
}

.s-page-shop-features__text {
    display: flex;
    align-items: center;
    justify-content: center;

    .s-icon {
        width: calc(#{$icon-size-usp} - 3px);
        height: calc(#{$icon-size-usp} - 3px);
        margin-right: 0.8rem;
        position: relative;
        top: -0.1rem;
        flex: 0 0 calc(#{$icon-size-usp} - 3px);
    }

    span {
        flex: 0 1 auto;
    }
}

.s-page-shop-features__text-multiple-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .s-page-shop-features__text {
        padding: 0 0.8rem;

        @include media-breakpoint-up(md) {
            padding: 0 3rem;
        }
        @include media-breakpoint-up(lg) {
            padding: 0 5rem;
        }
    }
}
