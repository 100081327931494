.minicart-items .product-item:not(:first-child) {
    border-top: none;
}

.minicart-wrapper {
    .action.showcart {
        .s-icon {
            margin-bottom: -0.4rem;
        }
    }
}

.minicart-items .product-item-details {
    padding-left: 9.9rem;
}
