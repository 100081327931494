.ui-datepicker {
    background: $color-white;

    .ui-datepicker-prev span {
        border-color: transparent $color-green transparent transparent;
    }
    .ui-datepicker-next span {
        border-color: transparent transparent transparent $color-green;
    }

    .ui-datepicker-calendar td {
        .ui-state-hover {
            background: rgba($color-green, 0.5);
        }
        .ui-state-active {
            background: $color-green;
            color: $color-white;
        }
    }
}
