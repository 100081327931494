.s-badges {
    position: absolute;
    bottom: 3.6rem;
    left: 1.6rem;
    font-size: 0;
}

.s-badges__item {
    span {
        --badge__background: #{$color-white};
        --badge__color: #{$color-black};

        background: var(--badge__background);
        color: var(--badge__color);
        font-size: $font-size__xs;
        display: inline-block;
        line-height: 1.6rem;
        font-weight: $font-weight__medium;
        padding: 0.1rem 0.5rem 0.3rem;

        @include media-breakpoint-up(md) {
            line-height: 2rem;
        }
    }
}

.s-badges__item--promotion-product {
    span {
        --badge__background: #{$color-promotion};
        --badge__color: #{$color-white};
    }
}

.s-badges__item--sale {
    span {
        --badge__background: #{$color-sale};
        --badge__color: #{$color-white};
    }
}

.s-badges__item--bestseller {
    span {
        --badge__background: #{$color-360rf};
        --badge__color: #{$color-white};
    }
}

@include min-screen($screen__m) {
    .s-badges {
        bottom: 1.6rem;
    }
}
