.s-sticky-bottom {
    position: sticky;
    bottom: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    left: 0;
    height: 6rem;
    z-index: $zindex-sticky;

    @include media-breakpoint-up(sm) {
        bottom: 5rem;
        height: auto;
    }

    @include media-breakpoint-up(md) {
        width: $back-to-top-button-size;
        left: 50%;
        transform: translateX(-50%);
    }

    > * {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}
