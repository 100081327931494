.form.create,
.form.subscribe,
.form.password.forget,
.form.password.reset,
.form.contact {
    margin: 0 auto;
    max-width: 60rem;
}

// Form Contact
.form.contact .field.telephone {
    width: 100%;
}

// Form Create Account
.form.create {
    .s-h4 {
        margin: 0;
    }

    .s-actions-toolbar {
        margin-bottom: 4rem;
    }
}

.field-dob {

    .customer-dob {
        input {
            width: 100%;
        }

        button {
            position: absolute;
            right: 0;
            top: -0.2rem;
        }
    }
}

// Form Newsletter Subscribe
.form.subscribe {

    .field.note {
        margin-top: 1.6rem;
    }

    .action {
        margin: 4rem 0;
    }
}
