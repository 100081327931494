.s-cart-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 7rem;

    .page-title-wrapper {
        width: 100%;

        h1 {
            margin-bottom: 0;
        }
    }

    .form.form-cart {
        width: 100%;
        order: 1;
    }

    .cart-summary {
        width: calc(100% + 3rem);
        margin-left: -1.5rem;
        order: 2;
    }

    &::before,
    &::after {
        display: none;
    }

    .action-edit,
    .action-delete {
        &:hover {
            text-decoration: none;
        }

        .s-icon {
            width: 2.5rem;
            height: 2.5rem;
        }
    }

    .s-button-link.update {
        margin-bottom: 2.6rem;
        color: $color-black;

        .s-icon {
            fill: $color-black;
        }
    }

    @include media-breakpoint-up(md) {
        .col.subtotal {
            position: relative;
        }
    }

    .col.qty {
        text-align: center;
    }

}

.s-cart-summary {
    width: 100%;
    background-color: $sidebar__background-color;
    box-shadow: $box-shadow;
    padding: $sidebar__padding;

    > .title {
        @include lib-font-size($h4__font-size);
        margin-top: 0;
        margin-bottom: 1.5rem;
    }

    .cart-totals {
        border-top: none;
        padding-top: 0;
    }

    .block.discount {
        margin-top: 3rem;
        margin-bottom: 4rem;
        border-top: none;

        &:first-child {
            color: $color-gray-middle2;
        }

        .amcoupons-apply > span {
            color: $link__color;
        }
    }

    .abs-discount-block.active > .title:after,
    .cart-summary .active.block > .title:after,
    .paypal-review-discount .active.block > .title:after {
        display: none;
    }


    .table {
        tr {
            th:last-of-type {
                @include media-breakpoint-up(sm) {
                    text-align: left;
                }
            }

            td:last-of-type {
                @include media-breakpoint-up(sm) {
                    text-align: right;
                }
            }
        }
    }

}

.amcoupons-total-coupons .mark,
tr.totals.discount .mark {
    text-align: left;
}

.amcoupons-total-coupons {
    .mark {
        // sass-lint:disable no-important
        text-align: left !important;
        // sass-lint:enable no-important
    }
}

.amcoupons-applied-coupon .amcoupons-remove:before {
    position: absolute;
    content: '';
    width: 1.5rem;
    height: 1.5rem;
    background-image: url('../images/myh-icon_close.svg');
    top: 0.4rem;
    left: 0;
}

.amcoupons-input {
    font-weight: $font-weight__bold;
}

.amcoupons-applied-coupon {
    padding-bottom: 1rem;
    position: relative;
    padding-left: 2rem;
}

.s-wrapper--delete-gift-card-code {
    display: flex;
    padding-bottom: 1rem;
}

.amcard-delete {
    position: relative;
    text-align: inherit;
    padding: 0;
    background: none;
    border: none;
    border-radius: unset;
    color: #000;
    width: 1.5rem;
    margin-right: 0.313rem;
    text-indent: -10000em;
    overflow: hidden;

    &:focus:not(:focus-visible) {
        box-shadow: none;
        color: inherit;
        background: #fff;
        border: none;
    }

    @media (hover: hover) {
        &:hover,
        &:hover:focus {
            color: #000;
            background: #fff;
            box-shadow: none;
            border: none;
        }
    }

    &:before {
        position: absolute;
        content: '';
        width: 1.5rem;
        height: 1.5rem;
        background-image: url('../images/myh-icon_close.svg');
        top: 0.4rem;
        left: 0;
    }
}








//
// Tablet
// ----------------------------------
@include media-breakpoint-up(md) {
    .s-cart-container {
        .s-button-link.update {
            margin-bottom: 0;
        }

        .cart-summary {
            width: calc(100% + 8rem);
            margin-left: -4rem;
        }
    }

    .s-cart-summary {
        position: static;
        padding: $sidebar__padding--desktop;
        margin-top: 4rem;
    }

}

//
// Desktop
// ------------------------------------
@include media-breakpoint-up(lg) {
    .s-cart-container {
        flex-direction: row;
        justify-content: space-between;

        .action-edit {
            margin-right: 1rem;
        }

        .s-cart-summary {
            position: relative;
            width: calc(100% / 12 * 4);
            margin-top: -4rem;
        }

        .s-form-cart {
            // sass-lint:disable no-important
            width: calc(100% / 12 * 7) !important;
            // sass-lint:enable no-important
        }
    }


}
