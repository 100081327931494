.fotorama__thumb__arr {
    box-shadow: none;
    background: $icon-arrow-background-color;
    border: $icon-arrow-border;
    border-radius: 4.8rem;
    height: 4.8rem;
    width: 4.8rem;

    .fotorama__thumb--icon {
        background-image: $icon__skip-right--black;
        background-size: 3rem;
    }

    &:hover,
    &:focus-visible {
        background-color: $primary__color;

        .fotorama__thumb--icon {
            background-image: $icon__skip-right--white;
        }
    }

    .fotorama__nav-wrap--horizontal &--left,
    .fotorama__nav-wrap--horizontal &--right {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 4.8rem;
        z-index: $z-index-10;
        cursor: pointer;
    }

    .fotorama__nav-wrap--horizontal &--left {
        left: 0;
    }

    .fotorama__nav-wrap--horizontal &--right {
        right: 0;
    }

    .fotorama__nav-wrap--vertical &--left,
    .fotorama__nav-wrap--vertical &--right {
        position: absolute;
        left: 50%;
        margin-left: -2.4rem;
        right: auto;
        height: 4.8rem;
        cursor: pointer;
        z-index: $z-index-10;
    }

    .fotorama__nav-wrap--vertical &--left {
        top: 1.6rem;
    }

    .fotorama__nav-wrap--vertical &--right {
        bottom: 1.6rem;
    }
}
