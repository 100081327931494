.pages {
    @include lib-pager();
    margin-bottom: 4rem;

    @include media-breakpoint-up(md) {
        margin-bottom: 6.6rem;
    }

    .action {
        width: 24px;
    }

    a.page.jump {
        margin: 0 -0.6rem;
    }
}
