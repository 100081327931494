
.s-accordion {
    .s-accordion__item {
        border-top: 0.1rem solid $color-accordion-border;
     }

    .s-accordion__header {
        margin: 0;
    }

    .s-accordion__button {
        @include lib-button-reset();
        padding: 2.5rem 0;
        width: 100%;
        position: relative;
        text-align: left;
        font-weight: $font-weight__medium;

        &:hover,
        &:focus,
        &:active {
            color: currentColor;
        }

        .s-accordion__arrow {
            position: absolute;
            right: 0;
            top: 50%;
            width: $icon-size-sm;
            height: $icon-size-sm;
            margin-top: calc(#{$icon-size-sm} / 2 * -1);
            transform: rotate(180deg);
        }

        &.collapsed .s-accordion__arrow {
            transform: rotate(0deg);
        }
    }
}
