.modal--made-in-green {
    iframe {
        height: 56rem;
        width: 100%;
        border: 0 none;

        @include media-breakpoint-only(xs) {
            height: 50vh;
        }
    }
}
