//
//  Tooltips variables
//  _____________________________________________

$tooltip__background    : $color-white !default;
$tooltip__border-color  : transparent !default;
$tooltip__border-width  : 0 !default;
$tooltip__border-radius : false !default;
$tooltip__color         : $primary__color !default;
$tooltip__cursor        : pointer !default;
$tooltip__font-size     : false !default;
$tooltip__font-family   : false !default;
$tooltip__font-weight   : false !default;
$tooltip__line-height   : $line-height__base !default;
$tooltip__font-style    : false !default;
$tooltip__margin        : false !default;
$tooltip__padding       : 3.4rem 4rem !default;
$tooltip__padding__small: 2rem 2.7rem !default;
$tooltip__min-width     : 210px !default;
$tooltip__max-width     : 270px !default;
$tooltip__z-index       : 100 !default;
$tooltip__box-shadow    : 0 0 10px -1px rgba(0, 0, 0, 0.14) !default;

$tooltip-arrow__size    : 0 !default;
$tooltip-arrow__offset  : 0 !default;
