.swatch-attribute-label {
    font-weight: bold;
    position: relative;
}

.swatch-attribute-label.required {
    padding-right: 1rem;
}

.swatch-attribute-label[data-required="1"]:after {
    content: '*';
    color: red;
    position: absolute;
    right: -1.1rem;
    top: -0.2rem;
    font-weight: bold;
    font-size: 1em;
}

.swatch-attribute-selected-option {
    color: #646464;
    padding-left: 1.7rem;
}

.swatch-option {
    padding: 0.1rem 0.2rem;
    min-width: 3rem;
    max-width: 9rem;
    height: 2rem;
    float: left;
    margin: 0 1rem 0.5rem 0;
    text-align: center;
    cursor: pointer;
    position: relative;
    border: 0.1rem solid rgb(218, 218, 218);
    overflow: hidden;
    text-overflow: ellipsis;
}

.swatch-option.text {
    box-sizing: border-box;
    color: black;
    font-weight: 100;
    padding: 0;
    margin-right: 0.7rem;
    border: 0.1rem solid transparent;
    width: 6.3rem;
    height: 3rem;
    background-color: #f1f1f1;
    border-radius: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up(xl) {
        width: 7rem;
    }

    @include media-breakpoint-up(xxl) {
        width: 8rem;
    }

    span {
        margin-bottom: -2px;
    }
}

.swatch-option.selected {
    outline: 0.2rem solid #ff5501;
    border: 0.1rem solid #fff;
    color: #333;
}


.clearfix:after {
    content: '';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.swatch-attribute.manufacturer .swatch-option {
    background: rgb(240, 240, 240);
    color: rgb(148, 148, 148);
}


.swatch-attribute.manufacturer .swatch-option.selected {
    color: black;
    background: #fff;
    border: 0.1rem solid #fff;
}

.swatch-option.text.disabled {
    color: $size-icon__active;

    &::after {
        background: $size-icon__active;
        width: 0.1rem;
        height: 3.2rem;
        left: 50%;
        transform: rotate(60deg);
        top: -0.1rem;
    }
}

.swatch-option.color.disabled {
    display: none;
}

.swatch-option:not(.disabled):hover {
    outline: 0.1rem solid #999;
    border: 0.1rem solid #fff;
    color: #333;
}

.swatch-option.image:not(.disabled):hover,
.swatch-option.color:not(.disabled):hover {
    outline: 0.2rem solid #ee0000;
    border: 0.1rem solid #fff;
}

.swatch-option.disabled {
    cursor: default;
}

.swatch-option.disabled:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(
        to left top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 42%,
        rgba(255, 255, 255, 1) 43%,
        rgba(255, 255, 255, 1) 46%,
        rgba(255, 82, 22, 1) 47%,
        rgba(255, 82, 22, 1) 53%,
        rgba(255, 255, 255, 1) 54%,
        rgba(255, 255, 255, 1) 57%,
        rgba(255, 255, 255, 0) 58%,
        rgba(255, 255, 255, 0) 100%
    );
}

.swatch-option-tooltip {
    max-width: 14rem;
    max-height: 100%;
    min-height: 2rem;
    min-width: 2rem;
    position: absolute;
    padding: 0.5rem;
    background: #fff;
    color: rgb(148, 148, 148);
    border: 0.1rem solid #adadad;
    display: none;
    z-index: 999;
    text-align: center;
}

.swatch-option-tooltip .corner,
.swatch-option-tooltip-layered .corner {
    left: 40%;
    position: absolute;
    bottom: 0;
    height: 0.8rem;
}

.swatch-option-tooltip .corner:after,
.swatch-option-tooltip-layered .corner:after {
    content: '';
    position: relative;
    top: 0.1rem;
    left: -1.5rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.7rem 0.75rem 0 0.75rem;
    border-color: #fff transparent transparent transparent;
    font-size: 0.1rem;
}

.swatch-option-tooltip .corner:before,
.swatch-option-tooltip-layered .corner:before {
    content: '';
    position: relative;
    top: 0.2rem;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.8rem 0.85rem 0 0.85rem;
    border-color: #adadad transparent transparent transparent;
    font-size: 0.1rem;
}

.swatch-option-tooltip .image,
.swatch-option-tooltip-layered .image {
    display: block;
    height: 13rem;
    width: 13rem;
    margin: 0 auto;
}

.swatch-option-tooltip .title {
    max-width: 14rem;
    min-height: 2rem;
    max-height: 20rem;
    color: rgb(40, 40, 40);
    text-align: center;
    display: block;
    overflow: hidden;
}

.swatch-opt {
    margin: 2rem 0;
}

.swatch-more {
    display: inline-block;
    margin: 0.2rem 0;
    // sass-lint:disable no-important
    text-decoration: none !important;
    // sass-lint:enable no-important
    position: static;
    z-index: 1;
}

// Layered Features
.swatch-option-link-layered {
    position: relative;
    // sass-lint:disable no-important
    margin: 0 !important;
    padding: 0 !important;
    // sass-lint:enable no-important
}

.swatch-option-link-layered:focus > div {
    box-shadow: 0 0 0.3rem 0.1rem #68a8e0;
}

.swatch-option-tooltip-layered {
    width: 14rem;
    position: absolute;
    background: #fff;
    color: rgb(148, 148, 148);
    border: 0.1rem solid #adadad;
    display: none;
    z-index: 999;
    left: -4.7rem;
}

.swatch-visual-tooltip-layered {
    height: 16rem;
    top: -17rem;
}

.swatch-textual-tooltip-layered {
    height: 3rem;
    top: -4rem;
}

.swatch-option-link-layered:hover > .swatch-option-tooltip-layered {
    display: block;
}

.swatch-attribute-options .swatch-option-tooltip-layered .title {
    width: 100%;
    height: 2rem;
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    color: rgb(40, 40, 40);
    text-align: center;
    margin-bottom: 1rem;
}

.swatch-option-disabled:after {
    content: '';
    position: absolute;
    width: 4.2rem;
    height: 0.2rem;
    background: red;
    transform: rotate(-30deg);
    z-index: 995;
    left: -0.4rem;
    top: 1rem;
}

.swatch-option-disabled {
    // sass-lint:disable no-important
    outline: none !important;
    // sass-lint:enable no-important
    cursor: default;
    border: 0;
}

// Bugfix for Add To Cart button
.swatch-opt-listing {
    margin-bottom: 1rem;
}

.photo.image.swatch-option-loading {
    content: url(../Magento_Swatches/images/loader-2.gif);
}

.product-image-wrapper.swatch-option-loading {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: url(../images/MYH-Loader-Transparent.gif) center no-repeat;
        background-position-y: calc(50% - 2rem);
        background-size: 4rem;
        background-color: rgba($color-white, 0.35);
    }
}

.swatch-input {
    left: -100rem;
    position: absolute;
    visibility: hidden;
}

.swatch-option.text.selected {
    border-color: black;
    outline: none;

}

.swatch-option.text:not(.disabled):hover {
    border: 0.1rem solid black;
    outline: none;
}

