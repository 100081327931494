.s-card-field-container {
    margin-top: 20px;

    .s-card-datalist-ul {
        margin-bottom: 12px;
    }

    .s-card-field-block {
        margin-bottom: 20px;
    }
}

.s-card-datalist-container {
    margin-bottom: 6px;

    .s-card-datalist-ul {
        display: none;
        list-style: none;
        padding-left: 0;

        > li {
            margin-left: 9px;
        }
    }

    &.-active {
        .s-card-datalist-ul {
            display: block;
        }
    }
}

.s-wrapper--gift-card-add-code {
    display: flex;
    align-items: center;

    .s-icon {
        height: 2.5rem;
        width: 2.5rem;
        fill: #4e9a1f;
    }
}

.s-card-codes-list {
    table {
        &:not(.cart):not(.totals):not(.table-comparison) > tbody {
            border-top: 1px solid #000;
            border-bottom: 1px solid #000;
        }

        &:not(.totals):not(.cart):not(.table-comparison) > tbody > tr td[data-th]::before {
            // sass-lint:disable no-important
            font-weight: 700 !important;
            font-size: inherit;
            // sass-lint:enable no-important
        }

        &.table-code-items {
            margin-bottom: 40px;

            .col.actions {
                .amcard-delete {
                    text-indent: unset;
                    overflow: unset;
                    color: #4e9a1f;
                    text-decoration: underline;

                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________
@include min-screen($screen__m) {
    .s-card-field-container {
        .s-card-field-block {
            width: calc(50% - 1rem);
        }
    }

    .s-card-codes-list {
        table {
            &.table-code-items {
                .col.actions {
                    .amcard-delete {
                        text-indent: -10000em;
                        overflow: hidden;
                        text-decoration: none;

                        &:before {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
