.s-styled-list {
    list-style: none;
    text-align: left;
    padding-left: 0;
}

.s-styled-list__item {
    position: relative;
    padding-left: 3.5rem;

    @include s-icon-background-before("check", "lg", false);

    &::before {
        position: absolute;
        left: 0;
    }
}

.s-styles-list__item-title {
    font-weight: $font-weight__medium;
}
