.s-footer-shop-information {

    .s-h5 {
        margin-top: 0;
        margin-bottom: 2.1rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 1.4rem;
        }
    }

    > .row {
        > div:not(:last-child) {
            padding-bottom: 3.6rem;

            @include media-breakpoint-up(lg) {
                padding-bottom: 0;
            }
        }
    }
}

.s-footer-shop-information__website-changer {

    select {
        width: auto;
    }
}

.s-footer-shop-information__icons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .s-footer-shop-information__icon:not(:last-child) {
        margin-right: 2.4rem;
    }

    img {
        max-height: 3.6rem;
        width: auto;
    }
}

.s-footer-shop-information__icon {
    margin-bottom: 1.9rem;
}
