//
//  Variables
//  _____________________________________________

$checkout-field-validation__border-color: $form-element-validation__border-error !default;

.field {
    .control {
        &._with-tooltip {
            @extend .abs-field-tooltip;
        }
    }

    &.choice {
        .field-tooltip {
            display: inline-block;
            margin-left: $indent__s;
            position: relative;
            top: -3px;
        }

        .field-tooltip-action {
            @include lib-css(line-height, $checkout-tooltip-icon__font-size);
        }
    }

    &._error {
        .control {
            input,
            select,
            textarea {
                @include lib-css(border-color, $checkout-field-validation__border-color);
            }
        }
    }

    .required-captcha.checkbox{
        position: absolute;
        display: block;
        visibility: visible;
        overflow: hidden;
        opacity: 0;
        width: 1px;
        height: 1px;
    }

}

.opc-wrapper {
    .fieldset {
        > .field {
            > .label {
                font-weight: $font-weight__regular;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________
