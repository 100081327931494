.opc-block-summary .minicart-items-wrapper {
    max-height: unset;
}

.opc-block-summary .product-item .product-item-name-block strong,
.opc-block-summary .product-item .price {
    //font-weight: $font-weight__semibold;
    //line-height: 1.18;
    @include lib-heading(h5);
}

.opc-block-summary .product-item .product-item-inner {
    margin-bottom: 0;
    margin-top: -0.5rem;
}

.opc-block-summary .product-item .old-price + .price {
    color: $color-sale;
}
