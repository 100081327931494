//
//  Components -> Modals
//  _____________________________________________

//
//  Variables
//  ---------------------------------------------

$modal-title__color: $text__color !default;
$modal-title__border: 1px solid $color-gray-light5 !default;

$modal-popup-title__font-size         : 20px !default;
$modal-popup-title-mobile__font-size  : $font-size__responsive__base !default;

$modal-slide__first__indent-left: 44px !default;
$modal-slide-mobile__background-color: $page__background-color !default;
$modal-overlay__background-color: rgba(255, 255, 255, 0.55) !default;

$modal-action-close__color: $primary__color !default;
$modal-action-close__font-size: 40px !default;
$modal-action-close__hover__color: $primary__color !default;

$modal-slide-action-close__padding: 1rem 4rem !default;

//
//  Common
//  _____________________________________________

.modal-custom,
.modal-popup,
.modal-slide {
    .action-close {
        @include lib-button-reset();
        @include lib-button-icon(
            $icon-remove,
            $_icon-font-color       : $minicart-icons-color,
            $_icon-font-size        : $modal-action-close__font-size,
            $_icon-font-line-height : $modal-action-close__font-size,
            $_icon-font-text-hide   : true
        );
        position: absolute;
        right: 0;
        top: 0;

        &:hover {
            &:before {
                color: $modal-action-close__hover__color;
            }
        }

        &::before {
            color: $modal-action-close__color;
            font-size: 4rem;
        }
    }
}

.modal-custom {
    .action-close {
        @include lib-css(margin, $indent__m);
    }
}

.modal-popup {
    pointer-events: none;

    .modal-title {
        @include lib-css(padding-bottom, $indent__s);
        min-height: 1em;
        word-wrap: break-word;
        margin: 0;
    }

    .action-close {
        padding: $indent__s;
    }

    &.modal-popup--small {
        .modal-inner-wrap {
            max-width: 65rem;

            @include media-breakpoint-down(sm) {
                width: 90%;
            }
        }

        &._show .modal-inner-wrap {
            transform: translateY(-50%);
            top: 50%;
            margin-top: 0;
            margin-bottom: 0;
        }

        .modal-header {
            padding-top: 2.4rem;
            padding-bottom: 0;
            align-items: flex-end;
        }

        .modal-content {
            padding-bottom: 4rem;

            @include media-breakpoint-up(sm) {
                padding-bottom: 6.4rem;
            }
        }

        .modal-header,
        .modal-footer,
        .modal-content {
            padding-left: 2.4rem;
            padding-right: 2.4rem;

            @include media-breakpoint-up(md) {
                padding-left: 3.8rem;
                padding-right: 3.8rem;
            }
        }

        .action-close {
            position: relative;
            padding: 0;
            margin-right: -1rem;

            &::before {
                font-size: 3rem;
                line-height: 3rem;

                @include media-breakpoint-up(md) {
                    font-size: 4.2rem;
                    line-height: 4.2rem;
                }
            }
        }
    }
}

.modal-slide {
    --modal-slide__fixed__width: 73rem;

    .action-close {
        padding: $modal-slide-action-close__padding;
    }

    .page-main-actions {
        margin-bottom: calc(#{$modal-slide-header__padding-vertical} - #{$indent__l / 2});
        margin-top: $modal-slide-header__padding-vertical;
    }

    &.s-minicart {
        max-width: var(--modal-slide__fixed__width);
        width: 100%;
        z-index: $zindex-modal !important;
    }
    &.s-size-modal-modal{
        max-width: var(--modal-slide__fixed__width);
        width: 100%;
    }

    &.modal-slide--ltr {
        left: 0;
        right: unset;
        max-width: 27rem;

        @include media-breakpoint-up(md) {
            max-width: 35rem;
        }

        .modal-inner-wrap {
            transform: translateX(-100%);
        }

        &._show .modal-inner-wrap {
            transform: translateX(0);
        }

        &._inner-scroll {

            .modal-inner-wrap {
                overflow-x: hidden;
            }

            .modal-header {
                padding-bottom: 1.9rem;
                padding-top: 2.4rem;
                margin-bottom: 0;

                @include media-breakpoint-up(md) {
                    padding: 4rem 4rem 3.1rem;
                }
            }
        }

        .modal-content {

            @include media-breakpoint-up(md) {
                padding-left: 4rem;
                padding-right: 3rem;
            }
        }

        .action-close {
            left: 0;
            right: unset;
            padding: 0;
            position: relative;
            margin-left: -1rem;

            &::before {
                font-size: 3rem;
                line-height: 3rem;

                @include media-breakpoint-up(md) {
                    font-size: 3.2rem;
                    line-height: 3.2rem;
                }
            }
        }

    }
}

.modals-overlay {
    @include lib-css(background-color, $modal-overlay__background-color);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

body {
    &._has-modal-custom {
        .modal-custom-overlay {
            height: 100vh;
            left: 0;
            position: fixed;
            top: 0;
            width: 100vw;
            z-index: $overlay__z-index;
        }
    }
}

@include media-breakpoint-down(sm) {
    .modal-slide {
        max-width: unset;
    }
}

.leaflet-container {
    min-height: 36rem;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__l) {
    .custom-slide {
        @include lib-modal();
        @include lib-modal-slide();

        &._show {
            -webkit-overflow-scrolling: touch;
            overflow-x: hidden;
            overflow-y: auto;
        }

        .modal-inner-wrap {
            @include lib-css(background-color, $modal-slide-mobile__background-color);
            box-sizing: border-box;
            height: auto;
            min-height: 100%;
        }
    }

    body {
        &._has-modal-custom {
            height: 100vh;
            overflow: hidden;
            width: 100vw;

            .modal-custom-overlay {
                @include lib-css(background-color, $modal-overlay__background-color);
            }
        }
    }
}

@include max-screen($screen__m) {
    .modal-popup {
        &.modal-slide {
            .modal-inner-wrap[class] {
                @include lib-css(background-color, $modal-slide-mobile__background-color);
            }

            &._inner-scroll {
                &._show {
                    -webkit-overflow-scrolling: touch;
                    overflow-y: auto;
                }

                .modal-inner-wrap {
                    height: auto;
                    min-height: 100%;
                }
            }
        }

        .modal-title {
            @include lib-font-size($modal-popup-title-mobile__font-size);
            @include lib-css(font-weight, $font-weight__bold);
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .modal-popup {
        &.modal-slide {
            .modal-footer {
                text-align: right;
            }
        }
    }
}
