.s-footer-service {

    .s-h5 {
        margin-top: 0;
        margin-bottom: 1.6rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 0.8rem;
        }
    }

    p {
        max-width: 42rem;
        margin: 0 auto 1.6rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 1.9rem;
        }
    }

    .row {
        > div {
            display: flex;
            flex-direction: column;

            .s-footer-service__item {

                &::after {
                    content: '';
                    position: absolute;
                    background-color: $color-white;
                    bottom: 1px;
                    left: 0;
                    right: 0;
                    top: auto;
                    height: 2px;

                    @include media-breakpoint-up(md) {
                        right: 1px;
                        left: auto;
                        top: 0;
                        bottom: 0;
                        height: auto;
                        width: 2px;
                    }
                }
            }

            @include media-breakpoint-only(md) {
                &:first-of-type {
                    .s-footer-service__item {
                        &::after {
                            bottom: 1px;
                            left: 0;
                            right: 0;
                            top: auto;
                            width: auto;
                            height: 2px;
                        }
                    }
                }
            }

            &:last-of-type {
                .s-footer-service__item {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}

.s-footer-service__item {
    background-color: $color-white-fog;
    height: 100%;
    position: relative;
    padding: 2.4rem 1rem 3.2rem;

    @include media-breakpoint-up(md) {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        padding: 4.1rem 1.8rem 4.6rem;
    }

    @include media-breakpoint-up(xl) {
        padding: 4.1rem 3.2rem 4.6rem;
    }

    a {
        margin: 0 auto;
    }

    .action.secondary {
        max-width: 26rem;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
}

.s-footer-service__phone {
    padding-top: 1rem;

    a {
        @include lib-font-size($h4__font-size);
        letter-spacing: 0.6px;
        vertical-align: middle;
        color: $font__color;

        @include media-breakpoint-up(xl) {
            @include lib-font-size(26);
        }
    }

    .s-icon {
        width: $icon-size-xl;
        height: $icon-size-xl;
        vertical-align: middle;
    }
}
