.s-size-guide {
    .container {
        box-sizing: border-box;
    }

    .mage-error {
        color: $alert-color__danger;
    }

    .shorten-pants-warning {
        text-align: center;
        @include lib-font-size($font-size__s);
        color: $alert-color__danger;
    }

    .size-calc__form-section,
    .size-calc__result-section,
    .shorten-pants-warning__message,
    .mage-error,
    #h3-invalid,
    #h3-invalid.active + #h3-valid {
        display: none;
    }

    .size-calc__form-section.active,
    .size-calc__result-section.active,
    #h3-invalid.active {
        display: block;
    }

    .size-calc-section {
        background-color: $section-background;
        padding-top: 5rem;
        padding-bottom: 4rem;
        margin-top: 4rem;
    }

    .field.schrittlange,
    .field.bundweite {
        width: calc(50% - 1rem);
    }


    .field.schrittlange {
        float: right
    }

    .field.bundweite {
        float: left;
        clear: both;
    }

    .s-size-guide__image-desktop,
    .s-size-guide__image-mobile {
        width: 100%;
    }

    .s-size-guide__image-desktop {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .s-size-guide__image-mobile {
        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    .result-section {
        flex: 1;
        text-align: center;
    }
}

