.opc-progress-bar {
    width: calc(100% / 12 * 7);
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;

    &:after {
        position: absolute;
        content: '';
        background-color: $color-black;
        height: 1px;
        top: 1.1rem;
        left: 0;
        width: calc(100% - 5rem);
        z-index: -1;

        @include media-breakpoint-up(xl) {
            top: 1.5rem;
        }
    }

    @include max-screen($screen__l) {
        width: 100%;
        padding-right: 0;
    }
}

.opc-progress-bar-item {
    padding-right: 1rem;
    width: auto;
    background-color: $page__background-color;

    &:before {
        display: block;
        top: 0.9rem;
        width: 0.5rem;
        height: 0.5rem;
        background-color: $color-black;

        @include media-breakpoint-up(xl) {
            top: 1.3rem;
        }
    }

    &:first-child:before,
    &:last-child:before {
        border-radius: 50%;
    }

    > span {
        padding-top: 0;
        padding-left: 1rem;
        color: $color-black;
        @include lib-font-size();
    }

    > span:after,
    > span:before {
        display: none;
    }

    &._active {
        > span {
            color: $color-green;
        }

        &:before {
            background-color: $color-green;
        }

        > span:after,
        > span:before {
            display: none;
        }
    }

}
