
.s-flipbook__container {
    height: 32rem;
    position: relative;

    @include media-breakpoint-up(md) {
        height: 73rem;
    }
}


