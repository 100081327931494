.fotorama__fullscreen {
    .s-product-info-media {
        .fotorama__nav,
        .fotorama__stage {
            transition: none;
        }
    }
}

.s-product-info-media {

    .s-badges {
        width: 100%;
        left: 50%;
        transform: translate(-50%, 0);

        .s-badges__item {
            padding: 0 1.6rem;
        }

        &::after {
            content: '';
            background: url("../images/myh-icon_enlarge.svg") no-repeat transparent;
            background-size: $icon-size-xl;
            background-position: center;
            position: absolute;
            right: 1.6rem;
            bottom: 0;
            height: $icon-size-xl;
            width: $icon-size-xl;
        }
    }

    .fotorama__stage {
        transition: height 0.5s;
        margin: 0 auto;
    }

    .fotorama__nav {
        transition: height 0.5s;
        height: 0;
    }
}

.fotorama--fullscreen {
    .fotorama__stage {
        max-height: unset !important;
    }
}
