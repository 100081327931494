.s-accordion {
    .s-collapse-mobile-button {
        color: $color-black;
        cursor: default;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;

        @include media-breakpoint-up(md) {
            padding-top: 0;
            padding-bottom: 0;
        }

        .s-icon {
            display: none;
        }
    }
}

.collapsable {
    .s-collapse-mobile-button {
        cursor: pointer;

        .s-icon {
            display: inline-block;
        }
    }
}
