// Light
@font-face {
    font-family: $font-family-name__base;
    src: url("../fonts/gilroy/5538949/a6096205-61ed-4c16-b76c-a56dd651ad33.woff2") format("woff2"),
        url("../fonts/gilroy/5538949/18f98fc7-4ddd-4d5e-94c0-c9b74a2325bd.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: $font-family-name__base;
    src: url("../fonts/gilroy/5538973/4035d916-a72e-4505-bc18-f1939f52f091.woff2") format("woff2"),
        url("../fonts/gilroy/5538973/fc3914af-bf83-428b-a85a-5e183f83fad7.woff") format("woff");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

// Regular
@font-face {
    font-family: $font-family-name__base;
    src: url("../fonts/gilroy/5538977/b261487c-2966-4089-8551-6555085406ad.woff2") format("woff2"),
        url("../fonts/gilroy/5538977/bf190033-35ff-42cf-b90c-f5321a94a3df.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: $font-family-name__base;
    src: url("../fonts/gilroy/5538981/cbada697-da92-4235-a272-9319d2618daa.woff2") format("woff2"),
        url("../fonts/gilroy/5538981/e78b39dd-ae82-4ccb-9e7e-34eb65ed3795.woff") format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

// Medium
@font-face {
    font-family: $font-family-name__base;
    src: url("../fonts/gilroy/5538985/94b9ba0e-1690-4966-8af8-7d8a1c22c221.woff2") format("woff2"),
        url("../fonts/gilroy/5538985/fd4654af-3e2f-4601-bb81-d6b08f68d9f0.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: $font-family-name__base;
    src: url("../fonts/gilroy/5538989/f2bc628e-29ba-4027-8000-256934a88ce8.woff2") format("woff2"),
        url("../fonts/gilroy/5538989/ed4ec59a-7b58-4c50-a807-3243d5c76766.woff") format("woff");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

// Bold
@font-face {
    font-family: $font-family-name__base;
    src: url("../fonts/gilroy/5538959/e62d07e6-5c21-45b8-8427-79c95511907d.woff2") format("woff2"),
        url("../fonts/gilroy/5538959/e667d70b-2326-4c62-8f38-200c31c3b08c.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: $font-family-name__base;
    src: url("../fonts/gilroy/5538997/59440bfe-8fae-4d2c-8fa5-f7a0bd33b83a.woff2") format("woff2"),
        url("../fonts/gilroy/5538997/e3a21507-45b4-403f-80d4-c3769bb8abb1.woff") format("woff");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

// Heavy
@font-face {
    font-family: $font-family-name__base;
    src: url("../fonts/gilroy/5539003/83cf0941-dd13-44b9-8dc0-b9161de3268e.woff2") format("woff2"),
    url("../fonts/gilroy/5539003/fae2b2de-865c-4574-8d42-33a62836725d.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: $font-family-name__base;
    src: url("../fonts/gilroy/5539008/69c2e98d-c307-4340-9429-e55e26fce5d8.woff2") format("woff2"),
    url("../fonts/gilroy/5539008/2e798628-4050-4677-acfa-f6173d284482.woff") format("woff");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

// Black
@font-face {
    font-family: $font-family-name__base;
    src: url("../fonts/gilroy/5539010/a8f2e830-5298-4862-825d-7a786de544b9.woff2") format("woff2"),
    url("../fonts/gilroy/5539010/dd4078e7-4f8a-4c69-aeec-4b1659b2555d.woff") format("woff");
    font-weight: 1000;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: $font-family-name__base;
    src: url("../fonts/gilroy/5539012/84f62042-f9d7-466e-817f-e98237995e38.woff2") format("woff2"),
    url("../fonts/gilroy/5539012/a1fde29f-46e7-4e45-a628-c3524a3bde0f.woff") format("woff");
    font-weight: 1000;
    font-style: italic;
    font-display: swap;
}

//
//  Custom Classes
//  _____________________________________________

// Headings
.s-h1 {
    @include lib-heading(h1);
}
.s-h1--special {
    @include lib-font-size($h1__font-size-spezial);
    @include lib-css(font-weight, $h1__font-weight-spezial);
    @include lib-css(line-height, 115%);
}
.s-h2 {
    @include lib-heading(h2);
}
.s-h3 {
    @include lib-heading(h3);
}
.s-h4 {
    @include lib-heading(h4);
}
.s-h5 {
    @include lib-heading(h5);
}
.s-h6 {
    @include lib-heading(h6);
}

// Text Size
.s-font-small {
    @include lib-font-size($font-size__s);
}

// Text styles
.s-font--strong {
    @include lib-css(font-weight, $font-weight__medium);
}


//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    h1 {
        @include lib-font-size($h1__font-size-desktop);
        @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
    }
}

.items {
    @include lib-list-reset-styles();
}
