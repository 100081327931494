//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background           : $color-yellow-light2 !default;
$message-global-note__border-color         : $color-yellow-light3 !default;
$message-global-note__color                : $text__color !default;

$message-global-note-link__color           : $link__color !default;
$message-global-note-link__color-hover     : $link__hover__color !default;
$message-global-note-link__color-active    : $link__active__color !default;

$message-global-caution__background        : $color-red9 !default;
$message-global-caution__border-color      : none !default;
$message-global-caution__color             : $color-white !default;

$message-global-caution-link__color        : $link__color !default;
$message-global-caution-link__color-hover  : $link__hover__color !default;
$message-global-caution-link__color-active : $link__active__color !default;

//  Header
$header__min-screen                        : 1500px !default;
$header__background-color                  : $color-white !default;
$header-icons-color                        : $color-gray56 !default;
$header-icons-color-hover                  : $color-gray20 !default;
$header__font-size                         : $font-size__xs !default;
$header__font-weight                       : $font-weight__semibold !default;
$header__color                             : $text__color !default;

$header-panel__font-weight                 : $font-weight__regular !default;
$header-panel__height                      : 3.4rem !default;

$addto-color                               : $text__color__muted !default;
$addto-hover-color                         : $primary__color !default;

$minicart-icons-color                      : $header-icons-color !default;
$minicart-icons-color-hover                : $header-icons-color-hover !default;

$button__shadow                            : inset 0 2px 1px rgba(0, 0, 0, 0.12) !default;

$h1__margin-bottom__desktop                : $indent__xl !default;

$menu-sustainablity__background-color      : $color-360rf;
$menu-sustainablity__color                 : $text__color__reversed;
$menu-sustainablity__border-radius         : 2.5rem;
$menu-sustainablity__padding               : 0 0 0 $icon-size-md;
$menu-sustainablity__padding--lg           : 0 0.9rem 0 calc(#{$icon-size-md} + 14px);
$menu-sustainablity__height                : 2rem;
$menu-sustainablity__line-height           : 1.3;
$menu-sustainablity__background-image      : $icon__nachhaltigkeit--weiss;
$menu-sustainablity__background-size       : 1.6rem;

$menu-burger__background-image             : $icon__navigation;
$menu-burger__background-size              : $icon-size-lg;
$menu-burger__padding                      : 0 0 0 $icon-size-lg;
$menu-burger__padding--lg                  : 0 0 0 calc(#{$icon-size-lg} + 12px);

$logo__width-small                         : 7.2rem;
$logo__width-big                           : 14rem;

// Header variables
.page-header {
    --header-nav__font-size                     : 0;
    --header-links__font-size                   : 0;
    --header-links__padding-bottom              : 0.6rem;
    --header-content__top                       : 0.6rem;

    --header-spacing__bottom                    : 0.8rem;
    --header-spacing__top                       : 0.6rem;

    --panel-header__height                      : 3.4rem;
    --panel-header__padding                     : 0;

    --header-nav-container__height              : 2.3rem;
    --header-nav-container__padding-top         : 0.5rem;
}
.page-header._sticky {
    --header__box-shadow                        : 0 0 12px 0 rgba(0, 0, 0, 0.11);
}
.page-product-configurable .page-header,
.page-header._sticky,
.page-header {
    --logo__width                               : #{$logo__width-small};
    --logo-text__height                         : 0;
    --panel-header__margin-top                  : calc((#{var(--panel-header__height)}) * -1);
}

@include media-breakpoint-up(md) {
    .page-header {
        --header-links__padding-bottom          : 0.8rem;

        --header-spacing__top                   : 0.8rem;
        --header-nav-container__padding-top     : 0.1rem;
    }
}
@include media-breakpoint-up(lg) {
    .page-header {
        --header-nav__font-size                 : #{$header__font-size};
        --header-links__padding-bottom          : 1.3rem;
        --panel-header__margin-top              : 0;

        --header-spacing__top                   : 0.9rem;
        --header-spacing__bottom                : 0.9rem;

        --logo__width                           : #{$logo__width-big};
        --logo-text__height                     : 1.4rem;

        --header-content__top                   : 0.6rem;

        &._sticky {
            --header-content__top               : 0.4rem;
        }
    }
}
@include media-breakpoint-up(xxl) {
    .page-header {
        --header-links__font-size               : #{$header__font-size};
    }
}

//  Footer
$footer__background-color                  : false !default;


body {
    @include lib-css(background-color, $page__background-color);
}

//
//  Header
//  ---------------------------------------------

.page-header {
    @include lib-css(background-color, $header__background-color);
    padding-bottom: 0;
    position: sticky;
    z-index: $zindex-fixed;
    box-shadow: var(--header__box-shadow);
    transition: box-shadow 0.5s;
    top: 0;
}

.header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    font-size: $header__font-size;
    font-weight: $header__font-weight;
    margin: var(--header-spacing__top) 0 0 0;
    padding-bottom: var(--header-spacing__bottom);
    transition: margin $header-transition-speed;

    .panel.wrapper,
    .header-nav-container {
        width: 50%;
        flex: 1 1 50%;
    }

    .header-nav-container {
        height: var(--header-nav-container__height);
        padding-top: var(--header-nav-container__padding-top);
    }

    .header_nav {
        font-size: var(--header-nav__font-size);

        @include media-breakpoint-down(md) {
            position: relative;
            top: -1px;
        }

        ul {
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;

            a.s-menu-burger {
                background: no-repeat left center transparent;
                background-image: $menu-burger__background-image;
                background-size: $menu-burger__background-size;
                padding: $menu-burger__padding;
                display: inline-block;
                min-height: $menu-sustainablity__height;

                @include media-breakpoint-up(lg) {
                    background-position: left -0.3rem;
                    padding: $menu-burger__padding--lg;
                }

                &.menu-item-active > span {
                    text-decoration: none;
                }

                span {
                    position: relative;
                    top: 0;
                }
            }

            a.s-menu-sustainablity {
                display: inline-block;
                background: no-repeat center center $menu-sustainablity__background-color;
                border-radius: $menu-sustainablity__border-radius;
                height: $menu-sustainablity__height;
                line-height: $menu-sustainablity__line-height;
                padding: $menu-sustainablity__padding;
                color: $menu-sustainablity__color;
                background-image: $menu-sustainablity__background-image;
                margin-left: 1.6rem;
                background-size: $menu-sustainablity__background-size;

                @include media-breakpoint-up(lg) {
                    background-size: $icon-size-md;
                    background-position: 0.7rem center;
                    padding: $menu-sustainablity__padding--lg;
                    margin-left: 2.4rem;
                }

                span {
                    position: relative;
                    top: 0.1rem;
                }
            }
        }
    }

    a {
        color: $header__color;
    }

}

.header-flyout {
    display: none;
}

body.search-active {
    .header.content {
        opacity: 0;
    }
}

.header {
    &.content {
        position: absolute;
        top: var(--header-content__top);
        left: 50%;
        transition: opacity $header-transition-speed;
        transform: translate(-50%, 0);

        .logo img {
            transition: width $header-transition-speed;
            width: var(--logo__width);
            height: auto;
            max-width: 100%;
        }

        .logo__text {
            overflow: hidden;
            position: relative;
            height: var(--logo-text__height);
            transition: height $header-transition-speed;
            display: block;
        }
    }

    &.panel {
        display: flex;
        justify-content: flex-end;

        &:not(.after) {
            transition: height $header-transition-speed, padding $header-transition-speed, margin $header-transition-speed;
            height: var(--panel-header__height);
            padding: var(--panel-header__padding);
            margin-top: var(--panel-header__margin-top);
            font-weight: $header-panel__font-weight;
            display: none;

            @include media-breakpoint-up(md) {
                display: flex;
            }

            .switcher-language {
                margin-left: 2rem;
                margin-right: 0;
            }
        }

        &.after {
            align-items: center;
        }

        .s-header--retailer-link {

            a {
                vertical-align: middle;
            }

            &::after {
                content: '';
                width: 1px;
                vertical-align: middle;
            }
        }
    }

    &.links {
        display: flex;
        margin: 0;
        padding: 0;

        .link {
            white-space: nowrap;
            display: flex;
            justify-content: center;
            align-items: center;
            list-style: none;
            margin: 0 0 0 0;

            @include media-breakpoint-up(lg) {
                margin-left: 1rem;
            }

            @include media-breakpoint-up(xl) {
                margin-left: 1.4rem;
            }

            > a {
                font-size: var(--header-links__font-size);
            }

            &.compare {
                display: none;
            }

            &.partner-link a,
            &.wishlist a,
            &.cart a {
                display: flex;
                align-items: center;

                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;

                    .s-text {
                        text-decoration: underline;
                    }
                }

                @include media-breakpoint-down(md) {
                    .s-icon {
                        width: 2.8rem;
                        height: 2.8rem;
                    }
                }
            }
            &.my-account {
                display: none;
                position: relative;

                @include media-breakpoint-up(sm) {
                    display: flex;
                    align-items: center;

                    @include lib-tooltip(
                        $_tooltip-position         : bottom,
                        $_tooltip-selector-toggle  : "a",
                        $_tooltip-min-width        : unset,
                    );
                }

                > a {
                    @include s-icon-background-before('login', 'lg', false);

                    @include media-breakpoint-up(lg) {
                        @include s-icon-background-before('login', null, false);
                    }

                    &::before {
                        vertical-align: middle;
                        background-position: left center;
                    }

                }

                .text-gotomyaccount {
                    display: none;
                }

                @include hover-focus() {

                    > a + .my-account__dropdown {

                        .tooltip-content {
                            text-align: left;
                        }

                        a {
                            @include lib-font-size();
                            font-weight: $font-weight__base;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}

.s-header-flyout__retailer-link a {
    position: relative;
    top: 0.2rem;
}

.logo {
    max-width: 100%;
    position: relative;
    z-index: 5;

    img {
        display: block;
    }
}

.page-main {
    > .page-title-wrapper {
        text-align: center;
        margin-top: 2rem;

        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.breadcrumbs + .page-main > .page-title-wrapper {
    margin-top: 0;
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        @include lib-css(background, $color-gray94);
        @include lib-css(padding, $indent__s);
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}

//
//  Header checkout
//  ---------------------------------------------

.checkout-index-index {

    .page-header {
        position: relative;

        .header.content {
            position: relative;
            margin-top: var(--header-content__top);
            top: 0;
            margin-bottom: 2.2rem;

            @include media-breakpoint-up(lg) {
                margin-bottom: 4rem;
            }
        }
    }
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }

    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;

        .actions {
            margin-top: $indent__s;
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
    @include lib-css(background-color, $footer__background-color);
    margin-top: auto;
}

.footer {
    &.content {
        border-top: 1px solid $border-color__base;
        margin-top: 25px;
        padding-bottom: 25px;
        padding-top: 25px;

        ul {
            @extend .abs-reset-list;
        }

        .links {
            > li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
        }
    }

    .copyright,
    .bugs {
        display: block;
        margin: 20px 0 0;
    }

    .field-recaptcha {
        display: none;
    }
}

.page-header,
.page-footer {
    .switcher {
        margin-right: 10px;

        .options {
            @include lib-dropdown(
                $_dropdown-toggle-icon-content        : $icon-down,
                $_dropdown-toggle-active-icon-content : $icon-up,
                $_icon-font-text-hide                 : true,
                $_icon-font-size                      : 22px,
                $_icon-font-line-height               : 22px,
                $_dropdown-list-min-width             : 5rem
            );

            .action.toggle.switcher-trigger.active,
            .action.toggle.switcher-trigger.active:active,
            .action.toggle.switcher-trigger {
                &::after {
                    color: $icon__color__active;
                    vertical-align: middle;
                }
                strong {
                    vertical-align: middle;
                }
            }

            ul.dropdown {
                left: -2.7rem;

                a {
                    display: block;
                }
            }
        }

        li {
            font-size: $font-size__s;
            margin: 0;
        }

        .label {
            @extend .abs-visually-hidden;
        }

        strong {
            font-weight: $font-weight__regular;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        @include lib-css(margin, $indent__base 0);
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer

        > .breadcrumbs,
        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }

        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }

    .navigation ul {
        padding: 0 8px;
    }

    .page-header {

        .header.panel {
            @extend .abs-add-clearfix-desktop;
        }

        .switcher {
            display: inline-block;
        }
    }

    .page-main {
        > .page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .logo {

        img {
            max-height: inherit;
        }
    }

    .footer {
        &.content {
            .block {
                float: right;
            }

            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }
    }
}
