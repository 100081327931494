.s-minicart {

    .minicart-items .product-item-details .price-including-tax,
    .minicart-items .product-item-details .price-excluding-tax {
        font-weight: $font-weight__medium;
    }

    .minicart-widgets {
        display: none;
    }

    .minicart-items .product-item {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 3rem;
    }

    .minicart-items .product-item:not(:first-child),
    .minicart-items-wrapper {
        border: none;
    }

    .minicart-items .product-item-details .old-price + .price {
        color: $color-sale;
    }
    .minicart-items .product-item-details .price {
        font-weight: $font-weight__medium;
        font-size: $font-size__base;
    }

    .s-minicart-action--delete {
        color: $link__color;

        &:hover {
            span {
                text-decoration: underline;
            }
        }

        .s-icon {
            fill: $link__color;
        }
    }

    .minicart-items-wrapper {
        box-sizing: border-box;
        padding-top: 0;
    }

    .action {
        &.close {
            &::before {
                font-size: 3.4rem;
            }
        }

        &.delete {
            &::before {
                display: none;
            }
        }
    }

    .edit {
        &:hover {
            text-decoration: none;
        }
    }

    .action.secondary,
    .action.primary {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1.6rem;
        text-align: center;

        @include media-breakpoint-down(xs) {
            width: 100%;
        }
    }

    .s-minicart__toCart {
        svg {
            width: 3rem;
            height: 3rem;
            margin-top: -2rem;
            margin-bottom: -1.5rem;
        }
    }

    .s-minicart__continue {
        border: none;
        outline: none;
        color: $link__color;
        margin: 0 auto;
        display: block;

        svg {
            transform: translateX(0) rotate(180deg);
            fill: $link__color;
            width: 3rem;
            height: 3rem;
            margin-bottom: -0.9rem;
            transition: transform 0.4s;
        }

        &:hover {
            color: $link__color;
            border: none;
            outline: none;
            background-color: transparent;

            svg {
                transform: translateX(-1rem) rotate(180deg);
            }
        }
    }

    .product-item-details {
        display: flex;
        justify-content: start;
        position: relative;
        flex-wrap: wrap;
        padding-left: 2.2rem;
    }

    .product-items-details--left {
        line-height: 1.2;
        max-width: 18rem;
        padding-right: 1rem;
    }

    .product-items-details--right {
        margin-left: auto;
    }

    .product.actions {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: end;
    }

    .product-item-name {
        font-weight: $font-weight__medium;
        margin-bottom: 0;

        a {
            color: $color-black;

            &:hover {
                text-decoration: none;
            }
        }

    }

}

.amazon-button-container {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.amazon-button-column.amazon-button-column-tooltip {
    display: none;
}

.amazon-button-container,
.amazon-checkout-button {
    margin-left: auto;
    margin-right: auto;
    max-width: 200px !important;
}

.amazonpay-button-view1 {
    margin: 0 auto;
}

.s-minicart-delete-wrapper {
    text-align: center;
    display: none;
    margin-top: 2rem;
}

.s-minicart-delete-product {
    margin-top: -0.5rem;

    @include hover-focus-active() {
        outline: none;
        border: none;
        background-color: transparent;
        color: $link__color;

        .s-icon {
            fill: $link__color;
        }
    }
}

.s-minicart-action {
    cursor: pointer;
    display: inline;

    &:first-of-type {
        margin-right: 2rem;
    }

    &:hover {
        text-decoration: underline;
    }

    &--delete {
        .s-icon {
            margin-bottom: -0.4rem;
            margin-left: -0.4rem;
        }
    }

    &--cancel {
        .s-icon {
            margin-bottom: -0.4rem;
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}

.s-minicart-delete-product,
.s-minicart-delete-product:hover,
.s-minicart-delete-product:active,
.s-minicart-delete-product:focus {
    border: none;
    outline: none;
    padding: 0;
}

