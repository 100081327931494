.s-checkout-success-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.s-checkout-success__order {
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    padding: 4rem 3rem;
    color: $color-white;

    background: url(../images/MEYER-Hosen-ThankYou-02.jpg) 0 0 no-repeat transparent;
    background-repeat: no-repeat;
    background-size: cover;

    .page-title {
        @include lib-font-size($h3__font-size);
    }

    .print {
        display: flex;
        justify-content: center;
        align-items: center;
        float: none;
        margin: 0.5rem;
        font-weight: $font-weight__medium;
        color: $color-white;

        .s-icon {
            margin-top: -0.2rem;
        }

        &:hover {
            .s-icon {
                fill: $color-white;
            }
        }
    }

    .order-number {
        position: relative;
        color: $color-white;
        @include s-icon-background-after('arrow__white', 'md', false);

        &:after {
            position: absolute;
            top: -0.3rem;
        }

        &:hover {
            &:after {
                transition: transform 0.4s;
                transform: translateX(1rem);
            }
        }
    }
}

.s-checkout-success__registration {
    display: flex;
    height: max-content;
    background-color: $page__background-color;
    box-sizing: border-box;
    padding: 4rem 3rem;
    box-shadow: $box-shadow;

    .s-h5 {
        margin-top: 0;
        margin-bottom: 1rem;
    }


}

//
// Tablet
// ----------------------------------

@include media-breakpoint-up(md) {
    .s-checkout-success-wrapper {
        background-image: url(../images/MEYER-Hosen-ThankYou-01.jpg);
        flex-direction: row;

        padding: 8rem 7.5rem;
    }

    .s-checkout-success__order {
        background-image: none;
    }
}

//
// Desktop
// ----------------------------------

@include media-breakpoint-up(lg) {
    .s-checkout-success__order {
        .page-title {
            @include lib-font-size($h1__font-size-spezial);
            @include lib-css(font-weight, $h1__font-weight-spezial);
            @include lib-css(line-height, 115%);
        }
    }
}
