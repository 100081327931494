.s-glossary {

    .ct-field-repeater_fields {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

}

.s-glossary-item {
    text-align: center;
    margin-bottom: 3rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 25rem;

    img {
        border-radius: 50%;
        width: 15rem;
        height: 15rem;
    }

    .s-h5 {
        margin: 0;
        padding: 0;

        @include media-breakpoint-down(xxs) {
            line-height: 22.5px;
        }
    }

    p {
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 0;

        &.expanded {
            text-overflow: clip;
            overflow: visible;
        }
    }

    .s-read-less,
    .s-read-more {
        display: none;
        justify-content: center;
        align-items: center;

        &::after {
            transition: $transition__transform;
        }
    }

    .s-read-less {
        display: none;
        @include s-icon-background-after("skip-up--green", "md", false);

        &:hover::after {
            transform: translateY(-0.5rem);
        }
    }

    .s-read-more {
        @include s-icon-background-after("skip-down--green", "md", false);

        &:hover::after {
            transform: translateY(0.5rem);
        }
    }
}
