.s-checkout-estimated {
    padding: 0;
    border-bottom: none;

    .action.showcart {
        outline: none;
        border: none;
        color: $link__color;

        &::before {
            display: none;
        }

        @include hover-focus-active() {
            outline: none;
            border: none;
            background-color: transparent;
            color: $link__color;
        }
    }

    @include media-breakpoint-up(md) {
        display: block;
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }
}
