$mobile_breakpoint: md;
$tablet_breakpoint: xl;

.s-product-detail-addtocart-sticky {
    background-color: $color-white;
    box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, 0.11);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $zindex-sticky;
    padding-top: 1.4rem;
    padding-bottom: 1.5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    .s-product-detail-addtocart-sticky__container {
        display: flex;
        width: 100%;
        flex-direction: column;

        @include media-breakpoint-up($tablet_breakpoint) {
            flex-direction: row;
        }
    }

    .s-product-detail-addtocart-sticky__container-infos {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1 0 auto;

        .price-box {
            margin-top: 0;
        }
    }

    .s-product-detail-addtocart-sticky__title {
        font-weight: $font-weight__medium;
        margin-right: 2.4rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        position: relative;

        @include media-breakpoint-up($mobile_breakpoint) {
            text-overflow: unset;
            overflow: inherit;
            white-space: normal;
        }
    }

    .s-product-choose-size-button {
        margin-bottom: 0;
        margin-right: 1.6rem;
    }

    .s-product-detail-addtocart-sticky__container-buttons {
        display: flex;
        width: 100%;

        @include media-breakpoint-up($tablet_breakpoint) {
            width: calc(100% - 1.2rem);
            margin-left: 2.4rem;
        }

        > div {

            &:first-child {
                margin-right: 0.8rem;
                width: calc(26% - 0.8rem);

                @media (min-width: 413px) {
                    width: calc(38% - 0.8rem);
                }

                @include media-breakpoint-down($mobile_breakpoint) {
                    button span {
                        display: block;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                }
            }
            &:last-child {
                margin-left: 0.8rem;
                width: calc(74% - 0.8rem);

                @media (min-width: 413px) {
                    width: calc(62% - 0.8rem);
                }
            }

            &:first-child,
            &:last-child {
                @include media-breakpoint-up(sm) {
                    width: calc(50% - 0.8rem);
                }
                @include media-breakpoint-up($tablet_breakpoint) {
                    width: 21rem;
                }
                @include media-breakpoint-up(xl) {
                    width: 23rem;
                }
            }
        }

        button {
            width: 100%;
        }
    }

    .s-product-detail-addtocart-sticky__container-buy {
        display: flex;
        margin-top: 0.4rem;

        @include media-breakpoint-up($mobile_breakpoint) {
            margin-top: 1rem;
        }
        @include media-breakpoint-up($tablet_breakpoint) {
            margin-top: 0;
        }
    }
}

.s-product-detail-addtocart-sticky__container-title {
    width: 80%;

    @include media-breakpoint-up($mobile_breakpoint) {
        display: flex;
        align-items: center;
        width: auto;
    }
}

.s-product-detail-addtocart-sticky__prize {

    @include media-breakpoint-down(sm) {
        .price-box.price-final_price {
            flex-direction: column-reverse;
        }

        .price-box.price-final_price .old-price {
            margin-right: 0;
        }
    }
}
