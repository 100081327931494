.product-video {
    bottom: 0;
    height: 100%;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}
