.table {
    border-color: $table-border-color;

    tr {
        td:first-of-type,
        th:first-of-type {
            padding-left: 0;
        }


        td:last-of-type,
        th:last-of-type {
            padding-right: 0;

            @include media-breakpoint-up(sm) {
                text-align: right;
            }
        }
    }
}

.table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr td:before,
.table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr th:before {
    font-weight: $font-weight__base;
    font-size: $font-size__s;
}
