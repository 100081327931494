.s-cms-content--responsible-teaser {

    .s-responsible-teaser {
        position: relative;
        display: block;

        a {
            color: $color-white;

            &::before {
                content: "";
                position: absolute;
                inset: 0;
            }
        }

        img {
            display: block;
        }
    }

    .s-responsible-teaser__text {
        position: absolute;
        bottom: 0;
        left: 2rem;
        right: 2rem;
        hyphens: auto;
        margin: 0;
        padding-bottom: 2rem;

        @include media-breakpoint-up(md) {
            left: 2.4rem;
            right: 2.4rem;
        }
        @include media-breakpoint-up(xl) {
            left: 3.2rem;
            right: 3.2rem;
            padding-bottom: 3rem;
        }
    }

    .s-cms-content__carousel-items {
        &:not(.swiper-initialized),
        .s-cms-content__carousel:not(.swiper-initialized) {
            margin: 0;

            .s-cms-content__carousel-item {

                @include media-breakpoint-up(lg) {
                    @include make-col(4);
                }
            }
        }

        .s-cms-content__carousel-item-padding {
            padding: 0;
        }
    }
}
