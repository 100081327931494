.s-filter-options {

    .s-filter-options-item {
        position: relative;
        border-top: 0.1rem solid $color-accordion-border;
    }


    .s-filter-options__arrow {
        position: absolute;
        right: 0;
        top: 1.5rem;
        width: 3rem;
        height: 3rem;
        transition: $transition__transform;
    }

    .s-filter-options-item.active {
        .s-filter-options__arrow {
            transform: rotate(180deg);
        }
    }

    .swatch-attribute-options {
        display: flex;
        flex-wrap: wrap;
    }

    .swatch-option.color {
        width: 2.6rem;
        height: 2.6rem;

        &:after {
            width: 1.4rem;
            height: 1.4rem;
            min-width: 1.4rem;
            max-width: 1.4rem;
            min-height: 1.4rem;
            max-height: 1.4rem;
        }
    }

    .s-filter-link {
        position: relative;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;

        a {
            color: $link__color;

            svg {
                fill: $link__color;
            }

            &:hover {
                text-decoration: none;
                color: $link__color;

                .s-icon--link-arrow {
                    transform: translateX(15px);
                }
            }
        }

        .s-icon--link-arrow {
            position: absolute;
            top: 4px;
            transform: translateX(5px);
        }
    }
}

.s-filter-btn {

    &.back-to-top {
        width: 200px;
    }

    .s-icon {
        fill: $color-white;
        margin-right: 1rem;
        height: 3rem;
        width: 3rem;
        margin-top: -6rem;
        margin-bottom: -6rem;
    }
}

.s-filter__group {
    margin-bottom: 2rem;

    .s-filter__group-title {
        width: 100%;
        float: left;
    }
}

.s-filter__sizetype {
    margin-bottom: 2rem;
}

.s-filter__sizetype-title {
    font-weight: $font-weight__medium;
}

.s-filter__group-title {
    margin-bottom: 1rem;
    margin-top: 2.4rem;
    display: block;
}


.s-filter__close-btn {
    background: none;
    border: none;
    padding-left: 0;

    &:active,
    &:hover {
        border: none;
        background: none;
    }
}

.s-filter-btn__text {
    position: relative;

    &:after {
        position: absolute;
        content: attr(data-filter-count);
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        font-size: 1rem;
        line-height: 1.7;
        background-color: $color-green;
        left: -2rem;
        top: 0.2rem;
    }

    &.hide-filter:after {
        display: none;
    }
}

.filter-current + .s-filter-actions {
    margin-bottom: 2.2rem;
}

.s-filter-actions {

    a {
        color: $font__color;

        &:hover {
            text-decoration: none;
        }
    }
}

.s-product-counter {
    margin-bottom: 2rem;
}

.s-filter-current {
    .action.remove .s-icon {
        width: 1.5rem;
        height: 1.5rem;
    }

    .s-filter-current__remove {
        position: absolute;
        right: -2rem;
        top: 3px;
    }
}

a.action.filter-clear,
a.action.remove {
    color: $font__color;

    &:hover {
        color: $link__color;
    }
}

a.action.filter-clear:hover {
    text-decoration: underline;
}

@include media-breakpoint-up(md) {

    .s-filter-btn,
    .s-filter__close-btn {
        // sass-lint:disable no-important
        display: none !important;
        // sass-lint:enable no-important
    }
}

body.filter-active {

    .s-filter-btn {

        .s-icon {
            display: none;
        }
    }

    .s-sticky-bottom {
        position: fixed;
        z-index: $zindex-fixed;
    }

    .s-back-to-top-button {
        // sass-lint:disable no-important
        display: none !important;
        // sass-lint:enable no-important
    }
}
