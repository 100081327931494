.swiper {
    --swiper-pagination-color: #{$color-black};
    --swiper-navigation-color: #{$color-black};
    --swiper-navigation-size: 1.8rem;
}

.swiper-pagination {
    position: relative;
    bottom: auto;
    top: auto;
    padding: 1.5rem 0;
}

.swiper-pagination-bullet {
    --swiper-pagination-bullet-size: 0.4rem;
    --swiper-pagination-bullet-horizontal-gap: 0;

    width: 1.9rem;
    height: 0.6rem;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    background: none;
    opacity: 1;
    vertical-align: middle;

    &::before {
        content: '';
        width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
        height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
        display: inline-block;
        border-radius: 50%;
        background: var(--swiper-pagination-bullet-inactive-color, #000);
        opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
        transition: width 0.2s, height 0.2s;
    }

    &:hover,
    &:focus,
    &:focus-visible {
        --swiper-pagination-bullet-size: 0.6rem;
        --swiper-pagination-bullet-inactive-opacity: 1;
    }
}

.swiper-pagination-bullet-active {
    --swiper-pagination-bullet-size: 0.6rem;

    &::before {
        background: var(--swiper-pagination-color);
        opacity: var(--swiper-pagination-bullet-inactive-opacity, 1);
    }
}

.swiper-button-prev, .swiper-button-next {
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    border: 1px solid $color-black;

    &:hover,
    &:focus,
    &:focus-visible {
        --swiper-navigation-color: #{$color-white};

        background-color: $color-black;
    }
}

.swiper-initialized {
    .product-items {
        flex-wrap: nowrap;
    }

    @include media-breakpoint-down(sm) {
        .swiper-wrapper.justify-content-center {
            justify-content: unset !important;
        }
    }
}
