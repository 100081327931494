.s-popup {
    display: none;

    .modal-popup & {
        display: block;
    }

    .modal-title {
        padding-bottom: 2.4rem;

        @include media-breakpoint-up(sm) {
            padding-bottom: 3.2rem;
        }
    }
}

.s-popup__content-buttons {
    display: flex;
    align-items: center;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
    }
}

.s-popup__content-image {
    margin-bottom: 3.2rem;

    img {
        display: block;
        width: auto;
        max-width: 100%;
        height: auto;
    }
}

.s-popup__close {
    cursor: pointer;
    margin-top: 1.5rem;

    @include media-breakpoint-up(sm) {
        margin-left: 4rem;
        margin-top: 0;
    }
}

.s-popup__content-text {
    p {
        margin-bottom: 3rem;
    }
}
