.s-dealer-locator {
    position: relative;
}

.s-dealer-locator__map {
    min-height: 48rem;
}

.s-dealer-locator__info-bar {
    display: flex;
    justify-content: space-between;
    padding: 2.4rem 0;
    border-bottom: 1px solid $border-color;
}

.s-dealer-locator__contact {
    display: flex;
    color: $color-black !important;

    svg {
        margin-left: 0.5rem;
        fill: $color-black !important;
    }
}

.s-js-dealer-count {
    font-weight: $font-weight__medium;
}


.s-dealer-locator__finder {
    display: none;
    background-color: $page__background-color;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 2rem 3rem;

    width: 25rem;

    position: absolute;
    top: 3rem;
    left: 3rem;
    z-index: 1;

    @include media-breakpoint-down(sm) {
        top: 1rem;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 10rem);
    }

    .s-dealer-locator__finder-input {
        margin-top: 2rem;
        margin-bottom: 1rem;
        @include lib-font-size($font-size__s);
    }

    .s-dealer-locator__finder-label {
        display: block;
        cursor: pointer;
        font-weight: $font-weight__medium;
        position: relative;
    }

    .s-dealer-locator__search-arrow {
        position: absolute;
        right: 0;
        transform: rotate(180deg);

        &.active {
            transform: rotate(0);
        }
    }
}


.s-dealer-locator__list-item {
    display: flex;
    flex: 1 1 0;
    justify-content: space-between;
    border-bottom: 1px solid #d1d1d1;
    padding: 2.6rem 0;

    &:first-of-type {
        border-top: 1px solid #d1d1d1;
    }

    .s-dealer-locator__list-wrapper {
        display: flex;
        justify-content: space-between;
        flex: 1 1 0;


        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }

    .s-dealer-locator__list-icon {
        width: 3.6rem;
        height: 3.6rem;
        margin-right: 5rem;
        background-size: 3rem 3rem;
        background: url('../images/dealer_locator/marker_meyer.png') no-repeat;


        @include media-breakpoint-down(sm) {
            margin-right: 2.5rem;
        }
    }

    .s-dealer-locator__list-title,
    .s-dealer-locator__list-address,
    .s-dealer-locator__list-map,
    .s-dealer-locator__list-contact {
        width: 25%;
    }

    .s-dealer-locator__list-map {
        width: 16rem;
        text-align: right;

        @include media-breakpoint-down(sm) {
            text-align: left;
        }

        .s-dealer-locator__link {
            color: $color-black;
        }
    }

    .s-dealer-locator__link {
        position: relative;
        padding-left: 3rem;

        &::before {
            position: absolute;
            content: '';
            width: 2.2rem;
            height: 2.2rem;
            background-size: cover;
            left: 0;
        }

        &--map {
            &::before {
                background-image: $icon__route;
            }
        }

        &--web {
            &::before {
                background-image: $icon__web--green;
            }
        }

        &--tel {
            &::before {
                background-image: $icon__tel--green;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .s-dealer-locator__list-title,
        .s-dealer-locator__list-address,
        .s-dealer-locator__list-contact,
        .s-dealer-locator__list-map {
            width: 100%;
            margin-bottom: 0.6rem;
        }
    }
}

.s-haendlersuche__infowindow {
    padding: 2px;

    .s-haendlersuche__infowindow-element {
        margin-bottom: 2px;

        &--title {
            font-weight: $font-weight__medium;
        }
    }
}

.s-dealer-locator-btn {
    cursor: pointer;
    width: 4rem;
    height: 4rem;
    background-color: $page__background-color;
    position: relative;
    box-shadow: rgb(0, 0, 0.3) 0 1px 4px -1px;
    border-radius: 2px;
    margin: 1rem;

    &::before {
        position: absolute;
        left: 50%;
        top: 50%;
        content: '';
        transform: translate(-50%, -50%);
        height: 1.8rem;
        width: 1.8rem;
        background-image: url('../images/myh-icon_locator.svg') ;
        background-position: center;
    }
}
