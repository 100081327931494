#s-size-modal {
    display: none;
}
.s-size-modal-modal {

    #s-size-modal {
        display: block;
    }
}

.s-size-modal__link {
    display: none;
}

.s-size-modal-modal--trousers {
    .s-size-modal__link--trousers {
        display: inline-block;
    }

    .s-size-selector__compare-label {
        display: block;
    }
}

.s-size-modal-modal--belts {
    .s-size-modal__link--belts {
        display: inline-block;
    }
}

.s-size-modal {
    .product-addtocart-button {
        max-width: 27rem;
        left: 50%;
        transform: translateX(-50%);
    }

    .s-size-modal__content {

        .s-badges,
        .s-catalog-teaser__brands,
        .swatch-attribute.color,
        .field.qty,
        .s-swatch-size-dummy,
        .s-product-choose-size-button {
            display: none;
        }

        .tocart {
            position: fixed;
            bottom: 2rem;
        }
    }

    .s-catalog-teaser__wrapper {
        .actions-primary {
            opacity: 1;
        }
    }

    .s-catalog-teaser__details,
    .product-image-container,
    .s-badges,
    .s-catalog-teaser__brands {
        display: none;
    }

    .s-product {
        list-style: none;
    }

    .product-item-actions {
        position: fixed;
        bottom: 0;
        z-index: 1;
    }

    .swatch-attribute-label,
    .swatch-attribute-selected-option {
        display: none;
    }
}

.s-size-modal__link {
    svg {
        fill: #4e9a1f;
        width: 22px;
        height: 24px;
        margin-bottom: -7px;
        transition: $transition__transform;
    }

    &:hover svg {
        text-decoration: none;
        color: $link__color;
        transform: translateX(1rem);
    }
}

.s-size-selector__thead {
    th {
        @include lib-font-size($font-size__s);
    }
}

.s-size-selector__group {
    margin-bottom: 1.5rem;

    .mage-error {
        // sass-lint:disable no-important
        display: none !important;
        // sass-lint:enable no-important
    }
}

.s-size-selector__size {
    color: $size-selector__inactive-color;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0;
    width: 7.5rem;

    &--selector {
        width: 10rem;
    }

}

.s-size-modal-modal--shirts,
.s-size-modal-modal--belts {

    .s-size-selector__size--selector {
        width: 50%;
    }
}


.s-size-selector__group-title {
    padding-left: 0;
    width: 14rem;
}

.s-size-selector__group-title--fixed {
    width: auto;
}

.s-size-selector__compare-label {
    padding-left: 12rem;
    padding-top: 2rem;
    @include lib-font-size($font-size__s);
    display: none;
}


@include min-screen($screen__m) {

    .s-size-selector__compare-label {
        padding-left: 17rem;
    }
}

.s-size-modal__header {
    margin-top: 1.6rem;
}

.header-fixed {
    position: fixed;
    top: 220px;
    display: none;
    z-index: 3;
    background-color: $page__background-color;
    width: 100%;
    transform: translateX(-60px);
    box-shadow: -1px 6px 8px -5px rgba(0, 0, 0, 0.35);
    padding-left: 60px;
}

.header-fixed__table {
    width: 39rem;

    th:first-of-type {
        width: 14rem;
        padding-right: 4.5rem;
    }

    th:nth-child(2) {
        width: 10rem;
    }

    th:nth-child(4),
    th:nth-child(3) {
        width: 7.5rem;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
    }
}

.s-size-modal__alert {
    display: none;
}
