.s-back-to-top-button {
    margin: 0;
    padding: 0;
    width: $back-to-top-button-size;
    height: $back-to-top-button-size;
    flex: 0 0 $back-to-top-button-size;
    background-color: transparent;
    border: 2px solid $primary__color;
    border-radius: 50%;
    color: $font__color;
    z-index: $zindex-fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: bottom 0.5s;

    .s-icon {
        transform: rotate(180deg);
    }

    &:hover,
    &:focus,
    &:active {

        .s-icon {
            transform: rotate(180deg) translateY(4px);
        }
    }
}

body.sticky-cart-button {
    .s-back-to-top-button {
        bottom: calc(#{$sticky-cart-height-xs} + 1rem);

        @include media-breakpoint-up(md) {
            bottom: calc(#{$sticky-cart-height-md} + 1rem);
        }
        @include media-breakpoint-up(xl) {
            bottom: calc(#{$sticky-cart-height-xl} + 1rem);
        }
    }
}
