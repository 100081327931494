$breakpoint: 'lg';

.s-footer-bottom-navigation {
    border-top: $border-width solid $border-color;
    display: flex;
    flex-direction: column;
    padding-top: 1.1rem;
    margin-top: 2.4rem;
    padding-bottom: 1.1rem;

    @include media-breakpoint-up($breakpoint) {
        flex-direction: row;
        justify-content: space-between;
        padding-top: 2.2rem;
        margin-top: 3.7rem;
    }
}

.s-footer-bottom-navigation__left {
    text-align: center;

    @include media-breakpoint-up($breakpoint) {
        text-align: left;
    }

    li {
        font-size: $font-size__s;
        line-height: $line-height__s;
        color: $text__color;

        a {
            font-size: $font-size__s;
            line-height: $line-height__s;
            color: $text__color;
            font-weight: 500;
        }
    }
}

.s-footer-bottom-navigation__login {
    display: inline-flex;
    align-items: center;

    @include media-breakpoint-up($breakpoint) {
        display: flex;
    }

    .s-icon {
        width: 2.3rem;
        height: 2.3rem;
        margin-right: 0.4rem;
    }

}

.s-footer-bottom-navigation__right {
    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        @include media-breakpoint-up($breakpoint) {
            justify-content: flex-end;
        }

        li {
            font-size: $font-size__s;
            line-height: $line-height__s;
            color: $text__color;

            &::after,
            a {
                font-size: $font-size__s;
                line-height: $line-height__s;
                color: $text__color;
                letter-spacing: 0.43px;
                vertical-align: middle;
                display: inline-block;
            }

            &::after {
                content: '·';
                font-size: $font-size__xl;
                padding-right: 0.5rem;
            }

            &:last-of-type {
                &::after {
                    padding-right: 0;
                    color: $text__color__reversed;
                    margin-right: -0.4rem;
                }
            }
        }
    }
}
