.s-cms-content--three-column-teaser {

    .s-cms-content__headline {
        @include media-breakpoint-up(sm) {
            margin-bottom: 0;
        }
    }

    .ct-field-repeater_fields {

        @include media-breakpoint-up(sm) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 0;
            margin-left: -1.5rem;
            margin-right: -1.5rem;

            .s-teaser-item {
                padding-left: 1.5rem;
                padding-right: 1.5rem;

                @include media-breakpoint-up(sm) {
                    margin-top: 2.4rem;
                }
                @include media-breakpoint-up(md) {
                    margin-top: 3.7rem;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(3, 1fr);
        }

        .s-teaser-item {
            position: relative;

            .ct-field-image {
                margin-bottom: 0.5rem;

                @include media-breakpoint-up(md) {
                    margin-bottom: 1rem;
                }

                img {
                    display: block;
                }
            }

            h3,
            p {
                margin: 0;
            }

            .s-link {
                position: static;

                &::before {
                    content: "";
                    position: absolute;
                    inset: 0;
                }
            }
        }
    }
}
