.s-customer-nav-open__wrapper {
    text-align: right;

    .s-button-link .s-icon {
        margin-bottom: -0.8rem;
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 1.6rem;
    }
}
.s-customer-nav-close,
.s-customer-nav-open {
    font-weight: $font-weight__medium;
    @include media-breakpoint-up(md) {
        display: none;
    }
}

.s-customer-nav-close {
    .s-icon {
        fill: $font__color;
    }
}

.sidebar-main._show {
    .s-customer-nav-close {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
}

