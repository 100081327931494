.s-anchor {
    display: block;
    position: relative;
    top: calc(-2rem - #{$header-sticky-height});

    @include media-breakpoint-up(md) {
        top: calc(-2rem - #{$header-sticky-height-md});
    }
}

