.s-icon {
    width: $icon-size-md;
    height: $icon-size-md;
    fill: $icon__color;
    transition: $transition__transform;
}

.s-icon--lg {
    width: $icon-size-lg;
    height: $icon-size-lg;
}

.s-icon--xl {
    width: $icon-size-xl;
    height: $icon-size-xl;
}

.s-icon--current-color {
    fill: currentColor;
}

.s-icon--color-secondary {
    fill: $icon__color__secondary;
}

.s-icon--link-reverse {
    transform: rotate(180deg);
}

button,
a {
    @include hover-focus-active() {
        .s-icon {
            fill: $icon__color__active;
        }

        .s-icon--current-color {
            fill: currentColor;
        }

        .s-icon--link-reverse {
            transform: rotate(180deg) translateX(0.4rem);
        }

        .s-icon--update {
            transform: rotate(180deg);
        }
    }
}

.s-icon-text-wrapper {
    padding-top: 1.6rem;
}

.s-icon--text {

    p {
        padding-bottom: 3rem;
        margin: 0;

        @include media-breakpoint-down(md) {
            word-break: break-word;
        }
    }

    .s-icon--text__icon {
        width: $icon-size-image-lg;
        height: $icon-size-image-lg;
        margin-bottom: 0.6rem;

        @include media-breakpoint-up(lg) {
            width: $icon-size-image-xl;
            height: $icon-size-image-xl;
        }
    }
}

.s-icon-text-wrapper--list {
    --icon-text-wrapper-list__icon__width: #{$icon-size-image-sm};
    --icon-text-wrapper-list__icon__height: #{$icon-size-image-sm};
    --icon-text-wrapper-list__icon__top: 0;

    padding-bottom: 0.8rem;

    .s-icon--text {
        position: relative;
        padding-left: $icon-size-image-sm + 2.9rem;

        .s-icon--text__icon {
            position: absolute;
            top: var(--icon-text-wrapper-list__icon__top);
            left: 0;
            width: var(--icon-text-wrapper-list__icon__width);
            height: var(--icon-text-wrapper-list__icon__height);
        }

        p {
            padding-bottom: 1.2rem;
            line-height: 1.9;

            @include media-breakpoint-up(sm) {
                line-height: 1.66;
                padding-bottom: 2.2rem;
            }
            @include media-breakpoint-up(xxl) {
                line-height: 1.5;
            }
        }
    }

    .s-link {
        display: inline-block;
        margin-top: 1.2rem;
        margin-bottom: 3.2rem;
    }
}

button:hover,
a:hover {
    .s-icon--link-arrow {
        transition: transform 0.4s;
        transform: translateX(1rem);
    }
}

.s-icon--link-arrow {
    &__white {
        fill: $color-white;
    }
}

.s-icon-counter {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    .s-icon {
        width: $icon-size-lg;
        height: $icon-size-lg;
    }

    .counter.qty {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        min-width: $icon-size-sm;
        height: $icon-size-sm;
        line-height: $icon-size-sm;
        background-color: $color-360rf;
        color: $text__color__reversed;
        font-size: 1rem;
        margin-left: -1rem;

        &.empty {
            display: none;
        }
    }
}
