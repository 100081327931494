body.cms-no-route {
    .landing-section,
    .page-title-wrapper {
        max-width: 60rem;
        margin-left: auto;
        margin-right: auto;

        p {
            margin-bottom: 2rem;
        }
    }
}
