//
//  UI-Datepicker
//  _____________________________________________
.amcard-page-product {
    .ui-datepicker {
        .ui-datepicker-title {
            display: flex;
        }
    }
}

//
//  Mobile
//  _____________________________________________
.page-products {
    &.category-giftcards {
        .products-grid {
            .product-item {
                max-width: 422px;
            }
        }

        .product-item-actions {
            .actions-primary + .actions-secondary {
                display: none;
            }
        }

        .product-image-container {
            span.product-image-wrapper {
                // sass-lint:disable no-important
                padding-bottom: 68% !important;
                // sass-lint:enable no-important
            }
        }

        .s-catalog-teaser {
            &:not(.s-catalog-teaser--no-cart) {
                .s-catalog-teaser__wrapper {
                    &:hover {
                        .actions-primary {
                            opacity: 1;
                        }
                    }
                }

                .s-catalog-teaser__details {
                    flex-direction: column;
                    align-items: center;

                    .s-catalog-teaser__name {
                        width: 100%;
                    }

                    .s-catalog-teaser__price {
                        width: 100%;
                        padding-left: 0;

                        .price-box.price-final_price {
                            align-items: flex-start;
                            text-align: left;

                            .price-box {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.s-product-info-main--gift-card {
    margin-bottom: 3.2rem;

    .product-addto-links {
        display: none;
    }

    .product-info-price {
        .price-box {
            .price {
                font-weight: 500;
                font-size: 20px;
                line-height: 14px;
            }
        }
    }

    .s-product {
        &.s-product--gift-card {
            [name="am_giftcard_image"] {
                display: none;
            }

            .amcard-form-container {
                margin-top: 40px;
            }

            .amcard-preview-container {
                .amcard-button--open-gift-preview {
                    margin-bottom: 0;
                }
            }

            .box-tocart {
                margin-top: 40px;

                .field.qty {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .control {
                        width: auto;
                    }
                }

                .actions {
                    align-items: flex-start;
                }
            }

            .amcard-field-block,
            .amcard-general-container,
            .amcard-preview-container,
            .amcard-radio-container,
            .amcard-form-container {
                margin-bottom: 1.6rem;
            }

            .amcard-radio-container {
                .amcard-label-block {
                    margin-left: 20px;

                    &:first-of-type {
                        margin-left: 0;
                    }
                }
            }

            .amcard-field-block {
                .amcard-label {
                    display: block;
                }

                &.-datepicker {
                    .amcard-label {
                        position: relative;

                        &::before {
                            z-index: 1;
                            position: absolute;
                            top: 10px;
                            left: 10px;
                            width: 16px;
                            height: 16px;
                            background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00Ljk4IDEwLjcwNWgxLjYzOFY5LjA2OUg0Ljk4djEuNjM2em0yLjMwNyAyLjE5aDEuNjM2di0xLjYzNkg3LjI4N3YxLjYzNXptLTIuMzA3IDBoMS42Mzh2LTEuNjM2SDQuOTh2MS42MzV6bTAtNC4zOGgxLjYzOFY2Ljg4SDQuOTh2MS42MzR6bTcuMDI4LTQuODIyYS42NzguNjc4IDAgMDAuNjc5LS42NzhWLjY3OGEuNjc4LjY3OCAwIDEwLTEuMzU4IDB2Mi4zMzdjMCAuMzc2LjMwMy42NzguNjc5LjY3OHptLTguMDE5IDBhLjY3OC42NzggMCAwMC42NzgtLjY3OFYuNjc4YS42NzguNjc4IDAgMTAtMS4zNTcgMHYyLjMzN2MwIC4zNzYuMzAzLjY3OC42NzkuNjc4em0tMS4zMTcgNy4wMTJINC4zMVY5LjA2OUgyLjY3MnYxLjYzNnptMCAyLjE5SDQuMzF2LTEuNjM2SDIuNjcydjEuNjM1em00LjYxNS0yLjE5aDEuNjM2VjkuMDY5SDcuMjg3djEuNjM2em00LjYxIDBoMS42MzlWOS4wNjloLTEuNjM4djEuNjM2em0wLTIuMTloMS42MzlWNi44OGgtMS42Mzh2MS42MzR6bTEuMDEzLTcuMzl2Ljc1NkExLjM2MiAxLjM2MiAwIDExMTAuNjQ1IDIuOWMwLS40MDcuMTgtLjc3MS40Ni0xLjAydi0uNzU0SDQuODkxdi43NTVhMS4zNjIgMS4zNjIgMCAxMS0xLjgwNCAwdi0uNzU1SDBWMTZoMTZWMS4xMjZoLTMuMDl6TTEuNTE3IDVoMTIuOTk2djkuNDgzSDEuNTE3VjV6bTUuNzcgMy41MTRoMS42MzZWNi44OEg3LjI4N3YxLjYzNHptMi4zMDcgMGgxLjYzNVY2Ljg4SDkuNTk0djEuNjM0em0wIDQuMzhoMS42MzV2LTEuNjM2SDkuNTk0djEuNjM2em0wLTIuMTloMS42MzVWOS4wN0g5LjU5NHYxLjYzNnoiIGZpbGw9IiM1NzU4NTUiLz48L3N2Zz4=');
                            content: '';
                            cursor: pointer;
                        }

                        input.amcard-field {
                            display: block;
                            background: #fff;
                            color: #000;
                            border: 1px solid #000;
                            border-radius: 2px;
                            font-weight: 300;
                            height: 36px;
                            line-height: 1;
                            padding: 3px 9px 0 36px;
                            vertical-align: baseline;
                            width: 100%;
                            box-sizing: border-box;
                            appearance: none;

                            &::placeholder {
                                font-weight: inherit;
                                color: inherit;
                                opacity: 1;
                            }
                        }
                    }
                }

                select {
                    font-weight: 300;
                }
            }
        }
    }

    .s-product-detail-addtocart-sticky {
        .s-product-detail-addtocart-sticky__container-buttons {
            justify-content: center;

            > div:first-child {
                display: none;
            }
        }
    }
}

.s-product-info-media--gift-card {
    .fotorama__stage,
    .gallery-placeholder {
        height: auto;
        text-align: center;

        @include min-screen($screen__l) {
            max-height: 460px;
            text-align: unset;
        }
    }
}


//
//  Desktop
//  _____________________________________________
.page-products {
    &.category-giftcards {
        @include min-screen($screen__m) {
            .s-catalog-teaser {
                &:not(.s-catalog-teaser--no-cart) {

                    .s-catalog-teaser__details {
                        flex-direction: row;

                        .s-catalog-teaser__name {
                            max-width: calc(100% - 100px);

                            .product-item-name {
                                margin: 10px 0 25px;
                            }
                        }

                        .s-catalog-teaser__price {
                            width: 100px;
                            padding-left: 1rem;

                            .price-box.price-final_price {
                                align-items: flex-end;
                                text-align: right;

                                .price-box {
                                    margin: 10px 0 25px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.s-product-info-main--gift-card {
    @include min-screen($screen__m) {
        .s-product {
            &.s-product--gift-card {
                max-width: 100%;
                margin: 0 auto;

                .amcard-flex-container {
                    display: flex;
                    justify-content: space-between;
                }

                .amcard-field-container {
                    width: calc(50% - 10px);
                }
            }
        }
    }
}



//
//  Desktop Modal
//  _____________________________________________
.modal-popup {
    &.amgiftcard-modal-container {

        .modal-content {
            .amcard-preview {
                table {
                    &.header-wrapper {
                        justify-content: center;
                    }
                }
            }
        }

        .modal-footer {
            text-align: center;

            button {
                margin: 0 auto;
            }
        }
    }
}
